<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Finance.Organizations') }}</h1>
      <DateSelector v-model="$app.startDate" />
      <DateSelector v-model="$app.endDate" />
    </div>
    <div v-if="stats" class="mt-16 flex w-full flex-col items-center justify-start">
      <span class="font-semibold">{{ stats.total.title }}</span>
      <div
        class="border flex w-full max-w-3xl flex-col items-center justify-center rounded-md border-neutral-50 bg-white py-4 sm:flex-row"
      >
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Professional.Stats.Count') }}</span>
          <span>{{ stats.total.count }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Professional.Stats.Active') }}</span>
          <span>{{ stats.total.active }}</span>
        </div>
      </div>

      <span class="mt-8 w-full max-w-3xl text-400 font-bold">{{ $t('Professional.RevenueForPlans') }}</span>
      <div class="mt-2 grid w-full max-w-3xl grid-cols-1 gap-8 sm:grid-cols-2">
        <div
          v-for="(stat, key) of stats.selected_period.revenue"
          :key="key"
          class="border flex flex-col items-start justify-center rounded-md border-neutral-50 bg-white px-8 py-4"
          :class="{
            'col-span-2':
              Object.keys(stats.selected_period.revenue).length % 2 == 1 &&
              Object.keys(stats.selected_period.revenue).indexOf(key) ===
                Object.keys(stats.selected_period.revenue).length - 1
          }"
        >
          <span class="mb-4 font-semibold"
            >{{ stat.title }}
            <span class="text-100 text-neutral-700"
              >({{ $t('Professional.Stats.Days', [stat.valid_for_days]) }})</span
            ></span
          >
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Professional.Stats.Price') }}</span>
            <span>{{ (stat.price * 100) | money }}</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Professional.Stats.Count') }}</span>
            <span>{{ stat.count }}</span>
          </div>
          <div class="flex w-full select-none flex-row items-center text-neutral-400">
            <div class="border-t flex-grow mr-2 border-neutral-200"></div>
            <span>&times;</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Professional.Stats.Revenue') }}</span>
            <span>{{ (stat.revenue * 100) | money }}</span>
          </div>
        </div>
      </div>

      <span class="mt-8 w-full max-w-3xl text-400 font-bold">{{ $t('Professional.ProfessionalUsage') }}</span>
      <div
        class="border mt-2 flex w-full max-w-3xl flex-col items-start justify-center rounded-md border-neutral-50 bg-white px-8 py-4"
      >
        <div class="flex w-full items-center justify-between font-semibold">
          <span>{{ $t('Professional.Stats.Name') }}</span>
          <span>{{ $t('Professional.Stats.Count') }}</span>
        </div>
        <div
          v-for="(stat, key) of stats.total.usage"
          :key="key"
          class="flex w-full flex-row items-center justify-between text-300"
        >
          <span class="flex-grow"
            >{{ stat.title }}
            <span class="text-100 text-neutral-700"
              >({{ $t('Professional.Stats.Days', [stat.valid_for_days]) }})</span
            ></span
          >
          <div class="text-300">
            <span>{{ stat.count }}</span>
          </div>
        </div>
        <div class="flex w-full select-none flex-row items-center text-neutral-400">
          <div class="border-t flex-grow mr-2 border-neutral-200"></div>
          <span>&plus;</span>
        </div>
        <div class="flex w-full items-center justify-between text-300">
          <span>{{ $t('Professional.Stats.Active') }}</span>
          <span>{{ stats.total.active }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceOrganizations',
  data() {
    return {
      stats: null
    };
  },
  watch: {
    '$app.startDate': {
      handler() {
        this.refresh();
        this.$forceUpdate();
      }
    },
    '$app.endDate': {
      handler() {
        this.refresh();
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.stats = await this.$api.get(
        `/admin/finance/organizations?start=${this.$app.startDate.getTime() / 1000}&end=${
          this.$app.endDate.getTime() / 1000
        }`
      );
    }
  }
};
</script>
