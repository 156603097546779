<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Search') }}</h1>
      <form @submit.prevent="search" class="flex w-full max-w-2xl flex-row space-x-4" autocomplete="off">
        <InputElement
          v-model="searchQuery"
          type="text"
          class="w-full"
          :placeholder="$t('Dashboard.Search.Placeholder')"
        />

        <ButtonElement
          type="submit"
          :text="$t('Dashboard.Search.Action.Search')"
          variant="secondary"
          size="md"
          class="flex-shrink-0"
        />
      </form>
    </div>
    <DataTable class="max-w-7xl mt-8 w-full" :data="results" @sortClicked="sortClicked" @rowClicked="rowClicked" />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Search',
  data() {
    return {
      searchQuery: '',
      results: []
    };
  },
  mounted() {
    this.searchQuery = _.get(this, '$route.params.query', _.get(this, '$route.query.search', '')) || '';
    this.search();
  },
  methods: {
    async search() {
      if (this.searchQuery.length === 0) {
        return;
      }

      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          search: this.searchQuery
        }
      });

      const response = await this.$api.post('/admin/search', { query: this.searchQuery });
      if (!response) {
        return;
      }

      this.searchQuery = response.query;
      this.results = response.results;
    },

    sortClicked() {
      // console.log(`Wants sort on key ${key}`);
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      let routeData = null;
      switch (item.entity) {
        case 'video':
          routeData = { name: 'Video', params: { videoID: item.id } };
          break;

        case 'invoice':
          routeData = { name: 'Invoice', params: { invoiceID: item.id } };
          break;

        case 'user':
          routeData = { name: 'User', params: { userID: item.id } };
          break;

        case 'organization':
          routeData = { name: 'Organization', params: { organizationID: item.id } };
          break;

        default:
      }

      if (!routeData) {
        return;
      }

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
