<template>
  <div
    class="relative flex flex-shrink-0 select-none items-center justify-center overflow-hidden rounded-full bg-primary font-bold text-white"
    :class="[
      { 'h-6 w-6 text-100': size === 'sm' },
      { 'h-10 w-10': size === 'md' },
      { 'h-12 w-12': size === 'lg' },
      { 'w-18 h-18 text-700': size === 'xl' }
    ]"
  >
    <span>{{ userCharacter }}</span>
    <img
      v-if="user && user.image_path"
      :src="user.image_path"
      class="absolute inset-0 h-full w-full object-cover"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  computed: {
    userCharacter() {
      if (!this.user) {
        return '';
      }

      let characters = '';
      if (this.user.first_name && this.user.first_name.length > 0) {
        characters += this.user.first_name.charAt(0).toUpperCase();
      }

      if (this.user.last_name && this.user.last_name.length > 0) {
        characters += this.user.last_name.charAt(0).toUpperCase();
      }

      if (characters.length < 2) {
        characters = this.user.username.charAt(0).toUpperCase();
        const atIndex = this.user.username.indexOf('@');
        if (atIndex > -1) {
          characters += this.user.username.charAt(atIndex + 1).toUpperCase();
        }
      }

      return characters;
    }
  }
};
</script>
