<template>
  <table class="min-w-max w-full table-auto overflow-hidden rounded-t-md text-300">
    <thead>
      <tr class="bg-neutral-900 text-white">
        <th v-if="!data || data.length === 0">
          <div class="h-8 cursor-pointer select-none py-1 px-6 text-left text-200 font-semibold">-</div>
        </th>
        <th
          v-for="(key, index) of keys"
          :key="`header-${index}`"
          class="hidden h-8 cursor-pointer select-none py-1 px-6 text-left text-200 font-semibold sm:table-cell"
          :class="{
            'font-bold': key === sort.replace('-', '')
          }"
          @click="sortClicked(key)"
        >
          <div class="flex flex-row items-center justify-start">
            <span>{{ key | prettykey }}</span>
            <IconChevron
              v-if="key === sort.replace('-', '')"
              :class="{
                'rotate-180 transform': sort.indexOf('-') === 0
              }"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="text-neutral-900">
      <tr v-if="!data || data.length === 0">
        <td class="transition py-3 px-6 text-left text-neutral-700 duration-150 ease-in-out group-hover:bg-neutral-200">
          {{ $t('DataTable.NoResults') }}
        </td>
      </tr>
      <tr
        v-for="(item, index) of data"
        :key="`row-${index}`"
        class="border-b group cursor-pointer border-neutral-50 odd:bg-neutral-50 even:bg-neutral-0"
        :class="{
          'opacity-50 pointer-events-none': item._disabled
        }"
        @click="rowClicked(item, $event)"
      >
        <td
          v-for="key of keys"
          :key="`value-${key}`"
          class="transition block py-3 px-6 text-left duration-150 ease-in-out group-hover:bg-neutral-200 sm:table-cell max-w-3xl truncate"
          :class="{
            'w-16': key === 'entity'
          }"
        >
          <span class="block font-bold sm:hidden">{{ key | prettykey }}</span>
          <div v-if="key === 'entity'" class="flex w-full flex-row gap-x-2">
            <EntityIcon :entity="item[key]" />
            <PaymentStatusIcon v-if="item.entity_payment_status" :status="item.entity_payment_status" />
          </div>
          <PaymentStatusIcon v-else-if="key === 'payment_status'" :status="item[key]" />
          <span v-else-if="key === 'title'" class="w-full">{{ item[key] }}</span>
          <span
            v-else-if="
              key.includes('amount') || key.includes('price') || key.includes('cost') || key.includes('income')
            "
            class="font-semibold"
            >{{ (item[key] * 100) | money }}</span
          >
          <span v-else-if="key.includes('date')">{{ item[key] | date(dateFormat) }}</span>
          <span v-else-if="key.includes('time') && !isNaN(item[key])">{{ Number(item[key]).toFixed(2) }} minutes</span>
          <span
            v-else-if="(key.includes('_seconds') || key.includes('duration')) && !isNaN(item[key])"
            :class="{
              'font-bold text-red-700': key.includes('check') && item[key] > 86400
            }"
            >{{ item[key] | duration(2) }}s</span
          >
          <span v-else>{{ item[key] }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    sort: {
      type: String,
      default: '',
      required: false
    },
    dateFormat: {
      type: String,
      default: 'DD MMMM YYYY',
      required: false
    }
  },
  computed: {
    keys() {
      if (!this.data || this.data.length === 0) {
        return [];
      }

      return Object.keys(this.data[0]).filter((key) => {
        return key !== 'id' && key !== '_disabled' && key !== 'entity_payment_status';
      });
    }
  },
  methods: {
    sortClicked(key) {
      this.$emit('sortClicked', key);
    },

    rowClicked(item, event) {
      if (!item?.id || item._disabled) {
        return;
      }
      this.$emit('rowClicked', item, event);
    }
  }
};
</script>
