<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <div
        class="border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
        @click="$router.go(-1)"
      >
        <IconChevronLeft />
      </div>
      <h1 class="text-700 font-bold">{{ $t('Menu.Management.Invoice') }}</h1>
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.Invoice.Action.CreateInvoice')"
        @click="createInvoice"
      />
    </div>
    <div v-if="item" class="w-full">
      <Card v-if="invoiceKeys" class="flex-col" :title="$t('Management.Invoice.SectionInvoice')">
        <template v-slot:content>
          <div class="flex items-center justify-end gap-2">
            <InputElement
              v-model="copyInvoiceId"
              type="text"
              :placeholder="$t('Management.Invoice.SectionInvoice.CopyFromExisting.Placeholder')"
            />
            <ButtonElement
              class="flex-shrink-0"
              variant="outline"
              size="md"
              :text="$t('Management.Invoice.Action.CopyFromExisting')"
              @click="copyInvoice"
            />
          </div>

          <form
            @submit.prevent="submitForm"
            class="grid grid-cols-1 flex-col gap-2 pt-8 sm:grid-cols-4 border-t border-b mt-8 border-1 border-gray-200"
          >
            <div class="mb-4 flex flex-col" v-for="key in invoiceKeys" :key="`video-${key}`">
              <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
              <InputElement v-model="item[key]" type="text" :placeholder="key" />
            </div>
          </form>

          <div class="mt-4 flex flex-col gap-4">
            <div v-if="!lines.length" class="text-200">{{ $t('Management.Invoice.SectionInvoice.NoLines') }}</div>
            <div v-else class="flex flex-col text-200">
              <div v-for="(line, index) in lines" :key="index" class="flex items-start gap-4 even:bg-neutral-50 p-2">
                <div class="flex flex-col gap-1">
                  <InputElement
                    v-model="line.description"
                    type="text"
                    placeholder="Description"
                    class="max-w-xl w-screen"
                  />
                  <span class="cursor-pointer text-100" @click="line.description = descriptionTemplate('video')"
                    >Video</span
                  >
                </div>
                <InputElement v-model="line.amount" type="number" class="w-24" placeholder="Amount" />
                <InputElement v-model="line.quantity" type="number" class="w-24" placeholder="Quantity" />
                <InputElement v-model="line.tax_rate" type="number" class="w-24" placeholder="Tax rate" />
                <InputElement v-model="line.purchase_id" type="number" class="w-32" placeholder="Purchase ID" />
                <InputElement v-model="line.purchase_credit_id" type="number" class="w-32" placeholder="P-Credit ID" />
                <InputElement v-model="line.organization_plan_id" type="number" class="w-32" placeholder="O-Plan ID" />

                <div class="mt-2 cursor-pointer text-red-800 hover:text-red-500" @click="removeLine(index)">
                  <IconTrash />
                </div>
              </div>
            </div>
            <div class="gap-4 flex">
              <ButtonElement
                class="flex-shrink-0"
                variant="outline"
                size="md"
                :text="$t('Management.Invoice.SectionInvoice.AddLine')"
                @click="addLine"
              />
              <ButtonElement
                class="flex-shrink-0"
                variant="outline"
                size="md"
                :text="$t('Management.Invoice.SectionInvoice.AddLinesFromVideoIds')"
                @click="addLinesFromVideoIds"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'InvoiceCreate',
  components: {
    Card
  },
  data() {
    return {
      item: null,
      copyInvoiceId: '',
      lines: []
    };
  },
  computed: {
    invoiceKeys() {
      return [
        'company',
        'name',
        'address',
        'postalcode',
        'city',
        'create_date',
        'organization_id',
        'user_id',
        'update_date',
        'expiry_date',
        'period_start',
        'period_end'
      ];
    }
  },
  mounted() {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    this.item = {
      create_date: new Date().toISOString().split('T')[0],
      update_date: new Date().toISOString().split('T')[0],
      expiry_date: oneMonthFromNow.toISOString().split('T')[0]
    };

    for (let i = 0; i < this.invoiceKeys.length; i += 1) {
      if (!this.item[this.invoiceKeys[i]]?.length) {
        this.item[this.invoiceKeys[i]] = '';
      }
    }
  },
  methods: {
    async copyInvoice() {
      if (!this.copyInvoiceId?.length) {
        return;
      }

      const invoice = await this.$api.get(`/admin/management/invoice/${this.copyInvoiceId}`);

      const newItem = {};
      for (let i = 0; i < this.invoiceKeys.length; i += 1) {
        if (!this.item[this.invoiceKeys[i]]?.length) {
          newItem[this.invoiceKeys[i]] = invoice[this.invoiceKeys[i]];
        }
      }

      this.item = {
        ...this.item,
        ...newItem
      };
    },

    addLine() {
      this.lines.push({
        description: '',
        amount: 0,
        quantity: 1,
        tax_rate: 21,
        purchase_id: null,
        purchase_credit_id: null,
        organization_plan_id: null
      });
    },

    async addLinesFromVideoIds() {
      const videoIds = prompt('Enter video IDs separated by comma')
        .split(',')
        .map((id) => id.trim());

      if (!videoIds.length) {
        return;
      }

      const promises = [];
      for (let i = 0; i < videoIds.length; i += 1) {
        const promise = this.$api.get(`/admin/management/video/${videoIds[i]}`).catch(() => null);
        promises.push(promise);
      }

      const videos = await Promise.all(promises);
      for (let i = 0; i < videos.length; i += 1) {
        const video = videos[i];
        if (!video) {
          this.lines.push({
            description: `Video ${videoIds[i]}`,
            amount: 0,
            quantity: 1,
            tax_rate: 21,
            purchase_id: null,
            purchase_credit_id: null,
            organization_plan_id: null
          });
          continue;
        }

        let minutePrice = video.plan?.minute_price ?? 0;

        if (video.general?.human_assistance) {
          minutePrice = video.plan?.human_price ?? 0;

          if (video.plan?.per_language && video.target_languages?.length) {
            const amountOfLanguages = video.target_languages.split(',')?.length ?? 1;
            minutePrice *= amountOfLanguages;
          }
        }

        this.lines.push({
          description: `${video.video.original_filename}\\n${video.plan?.title} - € ${minutePrice.toFixed(
            2
          )} per minute`,
          amount: minutePrice * Math.ceil(video.video.duration_seconds / 60),
          quantity: 1,
          tax_rate: 21,
          purchase_id: video.purchase.id,
          purchase_credit_id: null,
          organization_plan_id: null
        });
      }
    },

    removeLine(index) {
      this.lines.splice(index, 1);
    },

    descriptionTemplate(type) {
      if (type === 'video') {
        return 'trimmed.mp4\\nMachine-made - € 2,00 per minute';
      }

      return '';
    },

    async createInvoice() {
      const optionalFields = ['organization_id', 'user_id', 'period_start', 'period_end'];
      for (let i = 0; i < this.invoiceKeys.length; i += 1) {
        if (optionalFields.includes(this.invoiceKeys[i])) {
          continue;
        }

        if (!this.item[this.invoiceKeys[i]]?.length) {
          this.$api.message(`${this.invoiceKeys[i].replace(/_/g, ' ')} is required`);
          return;
        }
      }

      if (!this.lines.length) {
        this.$api.message('At least one line is required');
        return;
      }

      const response = await this.$api.post(`/admin/management/invoice/create`, {
        invoice: this.item,
        lines: this.lines
      });

      if (!response?.success) {
        this.$api.message(response?.message ?? 'Failed to create invoice');
        return;
      }

      this.$router.push({ name: 'Invoice', params: { invoiceID: response.invoiceID } });
      this.$api.message('Changes saved successfully');
    }
  }
};
</script>
