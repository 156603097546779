<template>
  <BaseModal :title="title" :withIconMargin="true" width="lg" v-bind="$props" @backdrop-clicked="hide">
    <template v-slot:icon>
      <div class="border rounded-full border-neutral-50 p-2">
        <IconClose class="text-primary-800" />
      </div>
    </template>

    <template>
      <span>{{ message }}</span>
    </template>

    <template v-slot:bottom>
      <ButtonElement
        class="flex-shrink-0"
        variant="primary"
        size="md"
        :text="$t(`Alert.Confirm.General.Action.Cancel`)"
        @click="hide"
      />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'AlertModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Message'
    },
    message: {
      type: String,
      required: true
    }
  }
};
</script>
