<template>
  <div
    class="rounded translate-z-0 pointer-events-none relative flex transform cursor-default select-none items-center justify-center overflow-hidden bg-neutral-100"
  >
    <div
      class="absolute inset-y-0 h-full w-screen animate-glow-button bg-neutral-900 bg-gradient-to-b from-neutral-100 via-neutral-900 to-neutral-100"
    ></div>
    <div class="rounded relative z-10 m-px overflow-hidden bg-white px-4 py-2">
      <ButtonElement v-bind="$props" size="md" variant="minimal">
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/global/elements/ButtonElement';

export default {
  name: 'GlowingButtonElement',
  extends: ButtonElement,
  inheritAttrs: false,
  components: {
    ButtonElement
  }
};
</script>
