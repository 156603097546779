<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Configuration') }}</h1>
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.Configuration.Action.Save')"
        @click="saveChanges"
      />
    </div>
    <div v-if="configuration" class="mt-8 flex w-full flex-col items-center justify-start gap-y-4">
      <div
        class="max-w-7xl border flex w-full flex-col items-start justify-center gap-y-4 rounded-md border-neutral-50 bg-white p-4"
      >
        <span class="font-bold">{{ $t('Management.Configuration.Section.Platform') }}</span>
        <div class="flex w-full flex-col text-300">
          <div
            v-for="(config, index) of configuration"
            :key="index"
            class="grid w-full items-center grid-cols-2 p-2 even:bg-neutral-0"
          >
            <div class="flex items-center gap-x-2">
              <span>{{ config.name | prettykey }}</span>
              <div
                class="relative flex h-4 w-4 cursor-pointer select-none items-center justify-center rounded-full bg-neutral-50"
                v-tippy="{ arrow: true }"
                :content="config.description"
              >
                <IconHelp />
              </div>
            </div>
            <InputElement type="text" v-model="config.value" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="staticConfiguration" class="mt-8 flex w-full flex-col items-center justify-start gap-y-4">
      <div
        class="max-w-7xl border flex w-full flex-col items-start justify-center gap-y-4 rounded-md border-neutral-50 bg-white p-4"
      >
        <span class="font-bold">{{ $t('Management.Configuration.Section.Static') }}</span>
        <div class="flex w-full flex-col text-300">
          <div
            v-for="(config, index) of staticConfiguration"
            :key="index"
            class="grid w-full items-center grid-cols-2 p-2 even:bg-neutral-0"
          >
            <div class="flex items-center gap-x-2">
              <span>{{ config.name | prettykey }}</span>
              <div
                class="relative flex h-4 w-4 cursor-pointer select-none items-center justify-center rounded-full bg-neutral-50"
                v-tippy="{ arrow: true }"
                :content="config.description"
              >
                <IconHelp />
              </div>
            </div>
            <InputElement type="text" v-model="config.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Configuration',
  data() {
    return {
      configuration: {},
      staticConfiguration: {}
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const response = await this.$api.get('/admin/management/configuration');
      if (response.success) {
        this.configuration = response.configuration || {};
        this.staticConfiguration = response.static || {};
      }
    },

    async saveChanges() {
      await this.$api.post(`/admin/management/configuration`, {
        configuration: this.configuration
      });

      this.refresh();
      this.$api.message('Changes saved successfully');
    }
  }
};
</script>
