<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.System') }}</h1>
    </div>
    <div v-if="stats" class="mt-8 flex w-full flex-col items-center justify-start gap-y-4">
      <div
        v-if="oldS3Objects && oldS3Objects.length > 0"
        class="max-w-7xl border flex w-full flex-col items-start justify-center gap-y-4 rounded-md border-neutral-50 bg-white p-4"
      >
        <span class="font-bold">Old S3 Objects</span>
        <div class="flex w-full flex-col text-300">
          <div class="grid w-full grid-cols-9 bg-white even:bg-neutral-0">
            <span class="col-span-2 font-bold">UUID</span>
            <span class="font-bold">Files</span>
            <span class="col-span-3 font-bold">Largest File</span>
            <span class="col-span-2 font-bold">Oldest Date</span>
            <div class="flex gap-x-1 font-bold">
              <span>Actions</span>
              <span class="cursor-pointer text-red-700 underline" @click="confirmDeleteAllS3Objects">(Delete all)</span>
            </div>
          </div>
          <div
            v-for="(s3object, index) of oldS3Objects"
            :key="index"
            class="grid w-full grid-cols-9 bg-white even:bg-neutral-0"
          >
            <a
              :href="`/search?search=${s3object.uuid}`"
              target="_blank"
              class="col-span-2 font-mono text-200 font-bold underline"
              >{{ s3object.uuid }}</a
            >
            <div>
              <span
                v-if="!s3object.loading"
                :class="{
                  'bg-red-700 px-2 font-bold text-white': s3object.deleted === s3object.files.length,
                  'bg-yellow-700 px-2 font-bold text-white':
                    s3object.deleted > 0 && s3object.deleted !== s3object.files.length
                }"
                >{{
                  s3object.deleted > 0 ? `DELETED ${s3object.deleted}/${s3object.files.length}` : s3object.files.length
                }}
                files</span
              >
              <img v-if="s3object.loading" src="@/assets/images/loading-inline.gif" alt="" />
            </div>
            <div class="col-span-3 flex justify-between pr-4">
              <span>{{ s3object.largestName }}</span>
              <span class="font-mono text-200">{{ (s3object.largestSize / 1000000).toFixed(2) }} MB</span>
            </div>
            <span class="col-span-2">{{ s3object.oldestDate | timeago(true) }} ({{ s3object.oldestDate | date }})</span>
            <div class="flex select-none gap-x-2">
              <a
                :href="`https://cloud.digitalocean.com/spaces/triple8?i=f3170c&path=videos%2F${s3object.uuid}%2F`"
                target="_blank"
                class="underline"
                >DO Spaces</a
              >
              <a :href="`https://editor.triple8.tv/${s3object.uuid}`" target="_blank" class="underline">Editor</a>
              <span
                v-if="!s3object.deleted"
                @click="confirmDeleteS3Object(s3object.uuid)"
                class="cursor-pointer font-bold text-red-700 underline"
                >Delete</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(substats, subkey) of stats"
        :key="subkey"
        class="max-w-7xl border flex w-full flex-col items-start justify-center gap-y-4 rounded-md border-neutral-50 bg-white p-4"
      >
        <span class="font-bold">{{ subkey | capitalize }}</span>
        <div v-if="!substats">
          <div v-if="$api.status.loading" class="flex gap-x-2">
            <img src="@/assets/images/loading-inline.gif" alt="" />
            <span>Loading...</span>
          </div>
          <ButtonElement
            v-else
            class="flex-shrink-0"
            variant="outline"
            size="md"
            :text="$t('Management.System.Action.Load')"
            @click="loadSubstat(subkey)"
          />
        </div>
        <div v-if="subkey === 'email'" class="grid grid-cols-2 w-full gap-4">
          <span class="col-span-2 font-mono whitespace-pre-line text-100">{{ stats.email.configuration }}</span>
          <InputElement type="email" :placeholder="$t('Management.System.Email')" v-model="email" />
          <select v-model="emailType">
            <option v-for="(template, index) in stats.email.templates" :key="index" :value="template">
              {{ template }}
            </option>
          </select>

          <textarea v-model="emailProperties" class="font-mono col-span-2 border border-neutral-50 text-200"></textarea>

          <ButtonElement
            class="flex-shrink-0 mt-4 col-span-2"
            variant="outline"
            size="sm"
            text="Send test email"
            @click="sendEmail()"
          />
        </div>
        <div v-else class="w-full">
          <div
            v-for="(stat, key) of substats"
            :key="key"
            class="flex w-full flex-col items-start justify-between bg-white text-300 even:bg-neutral-0 md:flex-row md:items-center"
          >
            <span class="text-neutral-700">{{ key.toString().replace(/_/, ' ') | capitalize }}</span>
            <span
              class="font-mono text-200"
              :class="{
                'bg-secondary px-8 font-bold text-white': isCritical(subkey, key, stat)
              }"
              v-html="valueForStat(subkey, key, stat)"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="directorStatuses && directorStatuses.length > 0"
      class="mt-8 flex w-full flex-col items-center justify-start"
    >
      <div
        class="max-w-7xl border flex w-full flex-col items-start justify-center gap-y-1 rounded-md border-neutral-50 bg-white p-4"
      >
        <div class="mb-4 flex w-full items-center justify-between">
          <span class="font-bold">{{ $t('Management.System.Directors') }} ({{ directorStatuses.length }})</span>
          <ButtonElement
            class="flex-shrink-0"
            variant="outline"
            size="md"
            :text="$t('Management.System.Action.Load')"
            @click="refreshDirectorStats"
          />
        </div>
        <div
          v-for="(status, index) of directorStatuses"
          :key="index"
          class="flex w-full flex-col p-2 text-300 even:bg-neutral-0 md:flex-row md:items-center md:p-0"
        >
          <span class="w-full md:w-1/4">{{ status.hostname | capitalize }}</span>
          <div class="ml-0 flex items-center gap-x-1 px-0 text-200 text-neutral-700 md:ml-4 md:px-2">
            <span class="font-semibold">{{ status.info || 'idle' }}</span>
            <span>-</span>
            <span>{{ status.updated_at | timeago }} </span>
          </div>
          <div class="flex-grow"></div>
          <div class="flex flex-col items-start gap-2 md:flex-row md:items-end">
            <div
              v-if="status.video_uuid"
              @click="navigateVideo(status, $event)"
              class="mr-0 cursor-pointer select-none md:mr-8"
            >
              <span class="underline">{{ status.video_name }}</span>
            </div>
            <span
              class="px-0 font-mono text-200 md:px-2"
              :class="{
                'bg-secondary font-bold text-white': status.load > 80,
                'bg-tertiary-800 font-bold text-white': status.load > 5 && status.load <= 80
              }"
              >CPU Load: {{ status.load }}%</span
            >
            <span
              class="px-0 font-mono text-200 md:px-2"
              :class="{
                'bg-tertiary-800 font-bold text-white': status.tasks > 0
              }"
              >Tasks: {{ status.tasks }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'System',
  data() {
    return {
      stats: null,
      directorStatuses: [],
      oldS3Objects: [],
      email: '',
      emailType: '',
      emailProperties: JSON.stringify({
        editor_url: 'https://www.google.com',
        safe_editor_url: 'https://www.google.com',
        invoice_url: 'https://www.google.com'
      })
    };
  },
  computed: {
    diskUsagePercentage() {
      if (!this.stats || !this.stats.filesystem) {
        return 0;
      }

      return Number(this.stats.filesystem.percentage.replace('%', ''));
    }
  },
  mounted() {
    this.refresh();

    this.email = this.$store.state.user.username;
  },
  methods: {
    async refresh() {
      this.stats = await this.$api.get('/admin/system/info');
      this.refreshDirectorStats();
    },

    async refreshDirectorStats() {
      const statuses = await this.$api.get('/admin/system/info/director');
      this.directorStatuses = (statuses || []).sort((a, b) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);
        return aDate.getTime() < bDate.getTime() ? 1 : -1;
      });
    },

    async loadSubstat(key) {
      try {
        const info = await this.$api.get(`/admin/system/info/${key}`);
        if (!info) {
          throw new Error('No info');
        }

        if (key === 's3') {
          this.oldS3Objects = this.parseS3Objects(info.s3.OldestObjects) || {};
          delete info.s3.OldestObjects;
        }

        this.stats = {
          ...this.stats,
          ...info
        };
      } catch {
        // Ignore error
      }
    },

    isCritical(subkey, key, stat) {
      if (!subkey || subkey.length === 0 || !key || key.length === 0) {
        return false;
      }

      if (subkey.toLowerCase() === 'filesystem') {
        if (['percentage', 'size', 'used'].includes(key.toLowerCase())) {
          return this.diskUsagePercentage >= 75;
        }
      } else if (subkey.toLowerCase() === 's3') {
        if (key === 'dangling_uploads') {
          return stat > 30;
        }
      }

      return false;
    },

    valueForStat(subkey, key, stat) {
      if (!subkey || subkey.length === 0 || !key || key.length === 0) {
        return '-';
      }

      if (subkey.toLowerCase() === 's3') {
        if (key.toLowerCase() === 'size') {
          return this.$filters.filesize(Number(stat) / 1000);
        }
      }

      if (Array.isArray(stat)) {
        return stat.join('<br />');
      }

      return stat;
    },

    navigateVideo(item, event) {
      if (!item || !item.id) {
        return;
      }

      const routeData = { name: 'Video', params: { videoID: item.video_id } };
      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    },

    parseS3Objects(data) {
      if (!data?.length) {
        return [];
      }

      const objects = [];

      for (let i = 0; i < data.length; i += 1) {
        const st = data[i];
        const regex = /^videos\/(.+?)\/(.*)$/;
        // eslint-disable-next-line no-unused-vars
        const [fullKey, uuid] = regex.exec(st.Key);
        if (!uuid?.length) {
          continue;
        }

        const date = new Date(st.LastModified);
        const filename = st.Key.replace(`videos/${uuid}/`, '');
        const existingObject = objects.find((object) => object.uuid === uuid);

        if (!existingObject) {
          objects.push({
            uuid,
            files: [st],
            oldestDate: date,
            largestSize: st.Size,
            largestName: filename,
            loading: false,
            deleted: 0
          });
          continue;
        }

        existingObject.files.push(st);
        existingObject.oldestDate = date > existingObject.oldestDate ? existingObject.oldestDate : date;
        if (st.Size > existingObject.largestSize) {
          existingObject.largestName = filename;
          existingObject.largestSize = st.Size;
        }
      }

      return objects;
    },

    async deleteS3Object(uuid) {
      const existingObject = this.oldS3Objects.find((object) => object.uuid === uuid);
      if (!existingObject) {
        return;
      }

      existingObject.loading = true;

      try {
        const response = await this.$api.post('/admin/management/s3/delete', {
          uuid
        });

        existingObject.loading = false;
        existingObject.deleted = response?.data?.deleted || 0;
      } catch (e) {
        // Ignore s3 error for now
      }
    },

    async confirmDeleteAllS3Objects() {
      this.$modal.confirm(async () => {
        // eslint-disable-next-line no-restricted-syntax
        for (const object of this.oldS3Objects) {
          // eslint-disable-next-line no-await-in-loop
          await this.deleteS3Object(object.uuid);
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, 400);
          });
        }

        this.loadSubstat('s3');
      });
    },

    confirmDeleteS3Object(uuid) {
      this.$modal.confirm(() => {
        this.deleteS3Object(uuid);
      });
    },

    async sendEmail(confirmed = false) {
      if (!confirmed) {
        this.$modal.confirm(() => {
          this.sendEmail(true);
        });
        return;
      }

      try {
        if (!this.email?.length) {
          throw new Error('No email');
        }

        if (!this.emailType?.length) {
          throw new Error('No email type');
        }

        if (!this.emailProperties?.length) {
          throw new Error('No email properties');
        }

        JSON.parse(this.emailProperties);
      } catch (e) {
        this.$api.message(e.message);
        return;
      }

      try {
        const response = await this.$api.post('/admin/management/email/send', {
          email: this.email,
          type: this.emailType,
          properties: JSON.parse(this.emailProperties)
        });

        this.$api.message(JSON.stringify(response?.data));
      } catch (e) {
        this.$api.message(e.message);
      }
    }
  }
};
</script>
