<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <div
        class="border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
        @click="$router.go(-1)"
      >
        <IconChevronLeft />
      </div>
      <h1 class="text-700 font-bold">
        {{ $t('Menu.Management.User') }} {{ item ? item.username : $route.params.userID }}
      </h1>
      <EntityIcon entity="user" />
    </div>
    <div class="mb-4 flex w-full flex-row items-center space-x-4">
      <img v-if="item && item.image_url" :src="item.image_url" alt="" class="h-16 w-16 rounded-full" />
      <div class="flex-grow"></div>
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.User.Action.Save')"
        @click="saveChanges"
      />
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t('Management.User.Action.OpenActions')"
        @click="actionsVisible = !actionsVisible"
      />
    </div>
    <div class="w-full">
      <div class="grid grid-cols-1 flex-col gap-8 pt-8 sm:grid-cols-2">
        <Card class="flex-col" :title="$t('Management.User.SectionGeneral')">
          <template v-slot:content>
            <div
              v-for="key in generalKeys"
              :key="`general-${key}`"
              class="flex w-full items-start justify-between text-300 even:bg-gray-200"
              :class="{
                'rounded-md bg-tertiary px-2 font-bold': key === 'organization_plan_pay_by_invoice' && item[key] > 0
              }"
            >
              <span class="text-neutral-700">{{ key.replace(/_/g, ' ') | capitalize }}</span>
              <span
                v-if="
                  key.indexOf('_date') > -1 ||
                  key === 'created' ||
                  key === 'updated' ||
                  key === 'deleted' ||
                  key === 'last_login'
                "
                >{{ item[key] | date }}</span
              >
              <a
                v-else-if="key === 'mollie_customer_id'"
                :href="`https://my.mollie.com/dashboard/org_9453701/customers/${item[key]}`"
                target="_blank"
                class="underline"
                >{{ item[key] || '-' }}</a
              >
              <span
                v-else-if="key === 'organization'"
                class="underline cursor-pointer"
                @click="showOrganization(item[key].id)"
                >{{ item[key].name || '-' }} ({{ item[key].id || '-' }})</span
              >
              <div v-else-if="key === 'role'">
                <span>{{ (item[key] || '-').replace('ROLE_', '').replace(/_/, ' ').trim() }}</span>
              </div>
              <span
                v-else
                :class="{
                  'font-mono text-200': key.indexOf('_id') > 0 || key.indexOf('_uuid') > 0 || key === 'iban'
                }"
                >{{ item[key] }}</span
              >
            </div>

            <Tabs class="mt-4" :titles="[$t('Video.Stats.Uploads')]">
              <template #tab1>
                <div v-if="item && item.uploads">
                  <DataTable :data="item.uploads" @rowClicked="videoRowClicked" />
                </div>
              </template>
            </Tabs>
          </template>
        </Card>

        <div class="flex flex-col gap-8">
          <Card v-if="userKeys" class="flex-col" :title="$t('Management.User.SectionUser')">
            <template v-slot:content>
              <form @submit.prevent="submitForm">
                <div class="mb-4 flex flex-col" v-for="key in userKeys" :key="`video-${key}`">
                  <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                  <InputElement v-model="item[key]" type="text" :placeholder="key" />
                </div>
              </form>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <ActionsPanel
      v-if="item"
      :user="item"
      :visible="actionsVisible"
      @hide="actionsVisible = false"
      @refresh="refresh"
      @change="change"
    />
  </div>
</template>

<script>
import set from 'lodash/set';
import Card from '@/components/Card.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';

export default {
  name: 'User',
  components: {
    Card,
    ActionsPanel
  },
  data() {
    return {
      item: null,
      actionsVisible: false
    };
  },
  computed: {
    generalKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'organization_id',
        'receives_mail',
        'activated',
        'is_active',
        'reset_requested',
        'image_url',
        'uploads'
      ];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    },
    userKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'organization_id',
        'is_active',
        'receives_mail',
        'activated',
        'last_login',
        'reset_requested',
        'organization',
        'created',
        'updated',
        'deleted',
        'role',
        'uploads'
      ];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.item = await this.$api.get(`/admin/management/user/${this.$route.params.userID}`);

      if (!this.item) {
        this.$router.push({ name: 'Dashboard' });
      }
    },

    async change(data) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        set(this.item, key, data[key]);
      }

      this.saveChanges();
    },

    async saveChanges() {
      if (typeof this.item.credit === 'string' && this.item.credit.includes(',')) {
        this.item.credit = this.item.credit.replace(',', '.');
      }

      await this.$api.post(`/admin/management/user/${this.$route.params.userID}`, {
        user: this.item
      });

      this.refresh();
      this.$api.message('Changes saved successfully');
    },

    showOrganization(organizationID) {
      this.$router.push({ name: 'Organization', params: { organizationID } });
    },

    videoRowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      const routeData = { name: 'Video', params: { videoID: item.id } };

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
