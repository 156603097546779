<template>
  <div class="relative">
    <svg class="block" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M18 4a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h12zm0 2H6v12h12V6z" fill="currentColor" fill-rule="nonzero"/></svg>
    <div v-if="checked" class="absolute inset-0 m-2 w-2 h-2 bg-primary rounded-sm animate-checkbox-check"></div>
  </div>
</template>

<script>
export default {
  name: 'IconCheckbox',
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
