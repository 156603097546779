import Vue from 'vue';
import axios from 'axios';

class TokenService {
  /* eslint-disable */
  async load() {
    if (!window.rootVue) {
      return await new Promise((resolve) => {
        setTimeout(async () => {
          resolve(await this.load());
        }, 100);
      });
    }
    const jwt = this.getToken();
    if (!jwt) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${jwt}`
    };

    try {
      const response = await axios.get(`${window.rootVue.$store.getters.apiBaseURL}/auth/me`, { headers });
      if (!response || !response.data || response.data.length === 0) {
        return false;
      }

      window.rootVue.$store.commit('user', response.data);
      window.rootVue.$store.commit('jwt', jwt);
    } catch (e) {
      return false;
    }

    return true;
  }

  getToken() {
    const token = Vue.$cookies.get('jwt');
    return token && token.length > 0 ? token : null;
  }

  saveToken(accessToken) {
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 12);
    Vue.$cookies.set('jwt', accessToken, expirationDate, '/', this.cookieDomain());
  }

  removeToken() {
    Vue.$cookies.remove('jwt', '/', this.cookieDomain());
  }

  cookieDomain() {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return window.location.hostname;
    }
    return `.${window.location.hostname.replace('business.', '').replace('account.', '').replace('admin.', '')}`;
  }
  /* eslint-enable */
}

const tokenInstance = new TokenService();

const tokenVue = {
  install(V) {
    V.prototype.$token = tokenInstance;
  }
};
Vue.use(tokenVue);

export default tokenInstance;
