<template>
  <div
    class="flex w-20 cursor-default select-none items-center justify-center rounded-md py-1 text-100 font-bold"
    :class="{ [this.classesForEntity(entity)]: true }"
  >
    {{ entity }}
  </div>
</template>

<script>
export default {
  props: {
    entity: {
      type: String
    }
  },
  methods: {
    classesForEntity(entity) {
      switch (entity) {
        case 'invoice':
          return 'bg-tertiary-800 text-white';

        case 'user':
          return 'bg-red-400 text-white';

        case 'video':
          return 'bg-primary-800 text-white';

        case 'organization':
          return 'bg-black text-white';

        default:
      }

      return 'bg-red-500 text-tertiary-600';
    }
  }
};
</script>
