<template>
  <div
    class="flex w-20 cursor-default select-none items-center justify-center rounded-md py-1 text-100 font-bold"
    :class="{ [classesForStatus(status)]: true }"
    v-tippy="{ arrow: true }"
    :content="statusExplanation(status)"
  >
    {{ status | capitalize }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String
    }
  },
  methods: {
    classesForStatus(status) {
      switch (status) {
        case 'paid':
          return 'bg-primary-800 text-white';

        case 'expired':
          return 'bg-secondary-700 text-white';

        case 'open':
          return 'bg-tertiary-800 text-white';

        case 'sent':
        case 'pending':
          return 'bg-primary-800 text-white';

        case 'failed':
        default:
      }

      return 'bg-black text-white';
    },

    statusExplanation(status) {
      switch (status) {
        case 'paid':
          return 'Betaald';

        case 'expired':
          return 'Verlopen (30 dagen)';

        case 'open':
          return 'Openstaand (handmatige betaling)';

        case 'sent':
        case 'pending':
          return 'Wachten op incasso (Mollie)';

        case 'failed':
          return 'Betaling mislukt';
        default:
      }

      return 'Geen uitleg beschikbaar';
    }
  }
};
</script>
