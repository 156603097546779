export default function _(value, valuta = '€') {
  if (value === null || value === undefined || Math.round(value) === 0) {
    return `${valuta} 0,00`;
  }

  value = Math.round(value);

  // eslint-disable-next-line
  return `${valuta} ${Math.floor(value / 100)},${(("00" + (value % 100)).slice(-2))}`
}
