<template>
  <div class="m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="flex flex-row items-center space-x-2 text-700 font-bold">
        <span>{{ $t('Menu.Management.BlacklistOverview') }}</span>
      </h1>
    </div>
    <div v-if="response" class="max-w-7xl mt-8 flex w-full flex-col items-start justify-center">
      <DataTable :data="response" date-format="DD-MM-YYYY HH:mm" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlacklistOverview',
  data() {
    return {
      response: null
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const rawResponse = await this.$api.get('/admin/management/blacklist/overview');
      this.response = rawResponse.map((item) => {
        if (item.email) {
          item.value = item.email;
          item.trigger_type = 'Email';
        } else if (item.video_filename) {
          item.value = item.video_filename;
          item.trigger_type = 'Filename';
        } else if (item.ip_address) {
          item.value = item.ip_address;
          item.trigger_type = 'IP Address';
        }

        delete item.email;
        delete item.video_filename;
        delete item.ip_address;
        return item;
      });
    }
  }
};
</script>
