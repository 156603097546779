<template>
  <div id="app" class="flex min-h-screen flex-col antialiased md:flex-row">
    <div
      v-if="$api.status.loading"
      class="border absolute top-0 right-0 z-50 mt-8 mr-8 flex h-16 animate-slide-x-in flex-row items-center justify-center space-x-2 rounded-md border-neutral-50 bg-white px-8 text-300 font-bold text-neutral-800"
    >
      <img src="@/assets/images/loading-inline.gif" alt="" />
      <span>Loading...</span>
    </div>

    <div
      v-if="$api.status.message"
      class="border absolute top-0 right-0 z-50 mt-8 mr-8 flex h-16 animate-slide-x-in flex-row items-center justify-center space-x-2 rounded-md border-neutral-50 bg-white px-8 text-300 font-bold text-neutral-800"
    >
      <span>{{ $api.status.message }}</span>
    </div>

    <Sidebar v-if="$store.state.jwt && $store.state.user" />
    <div
      class="flex-grow relative flex h-full min-h-screen w-full flex-col bg-neutral-0 px-2 pt-8 text-400 font-normal text-neutral-900 md:px-8"
    >
      <router-view class="flex-grow" />
    </div>
    <div
      class="pointer-events-none fixed inset-x-0 bottom-0 hidden select-none flex-row items-end justify-between px-8 pb-4 text-100 text-neutral-300 xl:flex"
    >
      <span :title="$store.getters.application.date">Version {{ $store.getters.application.commit }}</span>
      <span>Build {{ $store.getters.application.date | date('DD-MM-YYYY HH:mm') }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Sidebar from '@/components/Sidebar';

export default {
  name: 'App',
  components: {
    Sidebar
  },
  watch: {
    startDate: {
      handler() {
        if (this.startDate > this.endDate) {
          this.startDate = this.endDate;
        }
      }
    },
    endDate: {
      handler() {
        if (this.endDate < this.startDate) {
          this.endDate = this.startDate;
        }

        this.endDate.setHours(23);
        this.endDate.setMinutes(59);
        this.endDate.setSeconds(59);
      }
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null
    };
  },
  mounted() {
    Vue.prototype.$app = this;
    this.$token.load();

    let date = new Date();
    date.setDate(1);
    this.startDate = new Date(date.getTime());
    date.setMonth(date.getMonth() + 1);
    if (date.getTime() > Date.now()) {
      date = new Date();
    }

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    this.endDate = new Date(date.getTime());
  },
  methods: {
    logout() {
      this.$token.removeToken();
      this.$store.commit('jwt', null);
      this.$store.commit('user', null);
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>
