<template>
  <div class="m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="flex flex-row items-center space-x-2 text-700 font-bold">
        <span>{{ $t('Menu.Management.VideoOverview') }}</span>
      </h1>
    </div>
    <div v-if="response" class="max-w-7xl mt-8 flex w-full flex-col items-start justify-center">
      <div
        class="border m-auto mb-8 flex w-full max-w-3xl flex-row items-center justify-center rounded-md border-neutral-50 bg-white py-4"
      >
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.UploadsLastHour') }}</span>
          <span>{{ response.last_hour_uploads }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.StuckVideos') }}</span>
          <span>{{ response.stuck_videos.length }}</span>
        </div>
      </div>
      <div class="flex gap-x-4">
        <span class="my-4 text-400 font-semibold">{{ $t('Video.StuckVideos') }}</span>
        <div class="flex flex-row items-center space-x-2 text-300 font-semibold">
          <input type="checkbox" id="checkbox-unpaid-only" name="checkbox-unpaid-only" v-model="showRetranscribing" />
          <label for="checkbox-unpaid-only"
            >{{ $t('Video.StuckVideos.ShowRetranscribing') }} ({{
              response.stuck_videos.length - stuckVideos.length
            }})</label
          >
        </div>
      </div>
      <DataTable :data="stuckVideos" @rowClicked="rowClicked" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoOverview',
  data() {
    return {
      response: null,
      showRetranscribing: false
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    stuckVideos() {
      return this.response.stuck_videos.filter((item) => {
        if (item.retranscribing && !this.showRetranscribing) {
          return false;
        }

        return true;
      });
    }
  },
  methods: {
    async refresh() {
      this.response = await this.$api.get('/admin/management/video/overview');

      const retranscribeVideoIds = (this.$store.state.forceRetranscribes || []).map((info) => {
        return info.identifier;
      });

      this.response.stuck_videos = this.response.stuck_videos.map((item) => {
        if (retranscribeVideoIds.includes(item.id)) {
          item.retranscribing = true;
        }

        return item;
      });
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      const routeData = { name: 'Video', params: { videoID: item.id } };
      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
