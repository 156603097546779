<template>
  <BaseModal
    :title="$t(`Alert.Confirm.${variant}.Title`)"
    :withIconMargin="true"
    width="lg"
    v-bind="$props"
    @backdrop-clicked="hide"
  >
    <template v-slot:icon>
      <div class="border rounded-full border-neutral-50 p-2">
        <IconClose class="text-primary-800" />
      </div>
    </template>

    <template>
      <span>{{ $t(`Alert.Confirm.${variant}.Body`) | format(format) }}</span>
    </template>

    <template v-slot:bottom>
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t(`Alert.Confirm.${variant}.Action.Cancel`)"
        @click="hide"
      />
      <ButtonElement
        class="flex-shrink-0"
        variant="primary"
        size="md"
        :text="$t(`Alert.Confirm.${variant}.Action.Confirm`)"
        @click="confirm"
      />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'ConfirmModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  },
  props: {
    variant: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: '',
      required: false
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyDown);
  },

  methods: {
    confirm() {
      this.hide();
      this.$emit('didconfirm');
    },

    onKeyDown(e) {
      if (e.key === 'Escape') {
        this.hide();
      } else if (e.key === 'Enter') {
        this.confirm();
      }
    },

    cleanup() {
      document.removeEventListener('keyup', this.onKeyDown);
    }
  }
};
</script>
