import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';

class ApiService {
  status = Vue.observable({ loading: false, message: '' });

  /* eslint-disable */
  async message(text, displayMilliseconds = 2000) {
    this.status.message = text;
    setTimeout(() => {
      this.status.message = '';
    }, displayMilliseconds);
  }

  async get(url) {
    return this.request('GET', url);
  }

  async post(url, data = {}) {
    return this.request('POST', url, data);
  }

  async request(method, url, data = {}) {
    const jwt = window.rootVue.$token.getToken();
    if (!jwt) {
      return;
    }

    const headers = {
      Authorization: `Bearer ${jwt}`
    };

    try {
      const parameters = {
        method,
        url: `${window.rootVue.$store.getters.apiBaseURL}${url}`,
        headers
      };

      if (!_.isEmpty(data)) {
        parameters.data = data;
      }

      this.status.loading = true;
      const response = await axios(parameters);
      this.status.loading = false;

      if (!response || !response.data) {
        return null;
      }

      return response.data;
    } catch (e) {
      const status = _.get(e, 'response.status', 0);
      console.log(`Could not execute request (${status}) - ${method} ${url}`);
      console.log(e);

      if (status === 401) {
        window.rootVue.$app.logout();
      }
    }

    this.status.loading = false;
    return null;
  }

  /* eslint-enable */
}

const apiInstance = new ApiService();

const apiVue = {
  install(V) {
    V.prototype.$api = apiInstance;
  }
};
Vue.use(apiVue);

export default apiInstance;
