<template>
  <div
    ref="sidebar"
    class="border-r z-10 flex flex-shrink-0 cursor-default flex-col overflow-scroll border-neutral-100 bg-neutral-900 text-400 font-normal text-white sm:relative sm:min-h-screen sm:w-64 sm:pb-32"
    :class="{
      'relative h-16 sm:h-auto': !opened,
      'fixed inset-0 min-h-screen': opened
    }"
  >
    <div
      class="flex h-16 w-full flex-shrink-0 items-center justify-between bg-neutral-900 px-4 md:justify-center md:px-0"
      @click="opened = !opened"
    >
      <img src="@/assets/images/logo.svg" alt="Triple8 Admin" />
      <IconMenu class="block sm:hidden" />
    </div>
    <div
      v-for="(category, index) of routeCategories"
      :key="index"
      class="flex w-full flex-col items-center justify-start text-300"
    >
      <span v-if="index !== 'main'" class="w-full select-none bg-neutral-800 p-2 font-bold">{{
        $t(`Menu.${index}`)
      }}</span>
      <div
        v-for="(route, routeIndex) of category"
        :key="routeIndex"
        class="w-full cursor-pointer select-none p-2 font-semibold text-neutral-200 hover:text-white"
        @click="navigate(route)"
      >
        <span
          class="block rounded-md py-3 px-4 transition-all duration-300 ease-in-out"
          :class="{
            'bg-primary-800 text-white': route.name === $route.name
          }"
          >{{
            route.menuName.indexOf(' - ') > -1
              ? $t(`Menu.${route.menuName.split(' - ')[0]}.${route.menuName.split(' - ')[1]}`)
              : $t(`Menu.${route.menuName}`)
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Sidebar',
  data() {
    return {
      opened: false
    };
  },
  computed: {
    routeCategories() {
      if (_.get(this.$router, 'options.routes', []).length === 0) {
        return [];
      }

      const categories = { main: [] };
      const routes = this.$router.options.routes.filter((route) => {
        return route.menuName && route.menuName.length > 0;
      });

      for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i];
        if (route.menuName.indexOf(' - ') > -1) {
          const categoryName = route.menuName.split(' - ')[0];

          if (!categories[categoryName]) {
            categories[categoryName] = [];
          }

          categories[categoryName].push(route);
        } else {
          categories.main.push(route);
        }
      }

      return categories;
    }
  },
  methods: {
    navigate(route) {
      this.$router.push({ name: route.name });
      this.opened = false;
      this.$refs.sidebar.scrollTop = 0;
    }
  }
};
</script>
