<template>
  <button
    :type="type"
    @click="$emit('click', $event)"
    :disabled="disabled"
    :class="[
      'group translate-z-0 rounded outline-none focus:outline-none relative inline-flex transform select-none appearance-none items-center justify-center overflow-hidden font-semibold tracking-tight transition-all duration-200',
      // sizes
      { 'h-8 px-4': size === 'sm' && variant != 'minimal' && !$slots.iconCenter },
      { 'h-10 px-4': size === 'md' && variant != 'minimal' && !$slots.iconCenter },
      { 'h-14 px-6': size === 'lg' && variant != 'minimal' && !$slots.iconCenter },

      { 'h-8 h-8': size === 'sm' && $slots.iconCenter },
      { 'h-10 w-10': size === 'md' && $slots.iconCenter },
      { 'w-14 h-14': size === 'lg' && $slots.iconCenter },

      { 'text-300': size === 'sm' },
      { 'text-300': size === 'md' },
      { 'text-400': size === 'lg' },

      // variants
      { 'bg-primary text-white': variant === 'primary' },
      { 'bg-secondary text-white': variant === 'secondary' },
      { 'bg-tertiary text-tertiary-900': variant === 'tertiary' },
      { 'bg-tertiary-600 text-tertiary-900': variant === 'tertiary-600' },
      { 'text-neutral-700 hover:text-neutral-900': variant === 'minimal' && !colorizeText },
      { 'text-primary-800 hover:text-primary-900': variant === 'minimal' && colorizeText },
      { 'border border-neutral-100 text-neutral-700 hover:border-neutral-300': variant === 'outline' },
      { 'pointer-events-none opacity-50': disabled },

      // full-width
      { 'w-full': fullWidth === true }
    ]"
  >
    <div
      v-if="variant != 'minimal' && variant != 'outline'"
      :class="[
        'absolute inset-0 origin-top scale-y-0 transform bg-green-500 transition-transform duration-300 group-hover:origin-bottom group-hover:scale-y-100',
        // sizes
        { 'bg-primary-800': variant === 'primary' },
        { 'bg-secondary-800': variant === 'secondary' },
        { 'bg-tertiary-600': variant === 'tertiary' },
        { 'bg-tertiary-800': variant === 'tertiary-600' }
      ]"
    ></div>
    <div
      v-if="$slots.iconLeft"
      :class="[
        'relative -ml-1 flex-shrink-0',
        // sizes
        { 'mr-2': size === 'sm' || size === 'md' },
        { 'mr-4': size === 'lg' },
        // variants
        { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': variant === 'outline' }
      ]"
    >
      <slot name="iconLeft"></slot>
    </div>
    <div v-if="hasText" class="relative" :class="{ truncate: text.length > 5 }">
      {{ text }}
    </div>
    <div
      v-if="$slots.iconCenter"
      :class="[
        'relative',
        { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': variant === 'outline' }
      ]"
    >
      <slot name="iconCenter"></slot>
    </div>
    <div
      v-if="$slots.iconRight"
      :class="[
        'relative -mr-1 flex-shrink-0',
        // sizes
        { 'ml-2': size === 'sm' || size === 'md' },
        { 'ml-4': size === 'lg' },
        // variants
        { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': variant === 'outline' }
      ]"
    >
      <slot name="iconRight"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonElement',
  props: {
    type: {
      type: String,
      default: 'button',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    variant: {
      type: String,
      default: 'primary',
      required: false
    },
    size: {
      type: String,
      default: 'md',
      required: false
    },
    text: {
      type: String,
      default: undefined,
      required: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    colorizeText: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasIconLeft() {
      return !!this.$slots.iconLeft;
    },
    hasIconRight() {
      return !!this.$slots.iconRight;
    },
    hasText() {
      return this.text !== undefined;
    }
  }
};
</script>
