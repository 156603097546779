<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Finance.Invoices') }}</h1>
      <DateSelector v-model="$app.startDate" />
      <DateSelector v-model="$app.endDate" />
      <div class="flex gap-x-2 text-300 font-semibold">
        <span>Filter:</span>
        <PaymentStatusIcon
          v-for="(status, index) of statuses"
          :key="index"
          :status="status"
          class="cursor-pointer hover:opacity-100"
          :class="{
            'opacity-50': !activeStatuses.includes(status),
            'opacity-100': activeStatuses.includes(status)
          }"
          :style="{
            filter: activeStatuses.includes(status) ? 'grayscale(0)' : 'grayscale(70%)'
          }"
          @click.native="toggleFilter(status)"
        />
      </div>
      <div class="flex gap-x-2 text-300 font-semibold items-center cursor-pointer">
        <input v-model="pricesWithVAT" id="withVAT" name="withVAT" type="checkbox" />
        <label for="withVAT">{{ $t('Menu.Finance.WithVAT') }}</label>
      </div>
      <div>
        <span v-if="activeStatuses.length > 0" class="text-200 font-bold">{{
          $t('Invoices.OverviewTotal', [$filters.money(total)])
        }}</span>
      </div>
      <div class="flex-grow"></div>
      <div class="flex gap-x-2">
        <ButtonElement
          class="flex-shrink-0"
          variant="outline"
          size="md"
          :text="$t('Management.Invoice.Action.CreateInvoice')"
          @click="$router.push({ name: 'InvoiceCreate' })"
        />
      </div>
    </div>
    <div class="flex w-full flex-col items-center justify-start">
      <DataTable :data="invoices" :sort="sortBy" @sortClicked="sortClicked" @rowClicked="rowClicked" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceInvoices',
  data() {
    return {
      sortBy: '',
      invoices: [],
      statuses: ['expired', 'open', 'pending', 'failed', 'paid'],
      activeStatuses: [],
      refreshDebounceTimerReference: null,
      pricesWithVAT: false
    };
  },
  watch: {
    '$app.startDate': {
      handler() {
        this.refresh();
      }
    },
    '$app.endDate': {
      handler() {
        this.refresh();
      }
    },
    pricesWithVAT: {
      handler() {
        localStorage.setItem('pricesWithVAT', this.pricesWithVAT ? 1 : 0);

        this.refresh();
      }
    }
  },
  computed: {
    total() {
      return this.invoices.reduce((acc, invoice) => acc + invoice.amount * 100 || 0, 0);
    }
  },
  mounted() {
    this.pricesWithVAT = localStorage.getItem('pricesWithVAT') === '1';
    this.refresh();
  },
  methods: {
    async refresh(force = false) {
      if (!force) {
        if (this.refreshDebounceTimerReference) {
          clearTimeout(this.refreshDebounceTimerReference);
        }

        this.invoices = [];
        this.refreshDebounceTimerReference = setTimeout(() => {
          this.refresh(true);
        }, 700);
        return;
      }

      const rawInvoices =
        (await this.$api.get(
          `/admin/finance/invoices?sort=${this.sortBy}&start=${this.$app.startDate.getTime() / 1000}&end=${
            this.$app.endDate.getTime() / 1000
          }&statuses=${this.activeStatuses.join(',')}`
        )) || [];

      this.invoices = rawInvoices.map((invoice) => {
        if (this.pricesWithVAT) {
          invoice.amount *= 1.21;
        }

        return invoice;
      });
    },

    sortClicked(key) {
      if (this.activeStatuses?.length) {
        return;
      }

      if (this.sortBy.length === 0) {
        this.sortBy = key;
      } else if (this.sortBy === key) {
        this.sortBy = `-${key}`;
      } else {
        this.sortBy = '';
      }

      this.refresh();
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      const routeData = { name: 'Invoice', params: { invoiceID: item.id } };

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    },

    toggleFilter(status) {
      if (this.activeStatuses.includes(status)) {
        this.activeStatuses.splice(this.activeStatuses.indexOf(status), 1);
      } else {
        this.activeStatuses.push(status);
      }

      this.refresh();
    }
  }
};
</script>
