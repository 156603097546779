import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';

export default function _(value) {
  if (value === null || value === undefined) {
    return null;
  }

  const date = dayjs().add(value, 'seconds');
  dayjs.extend(relativeTime);

  let { locale } = window.rootVue.$i18n;
  const supportedLocales = ['en', 'nl'];
  if (supportedLocales.indexOf(locale) === -1) {
    locale = 'en';
  }

  return date.locale(locale).fromNow(true);
}
