var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"min-w-max w-full table-auto overflow-hidden rounded-t-md text-300"},[_c('thead',[_c('tr',{staticClass:"bg-neutral-900 text-white"},[(!_vm.data || _vm.data.length === 0)?_c('th',[_c('div',{staticClass:"h-8 cursor-pointer select-none py-1 px-6 text-left text-200 font-semibold"},[_vm._v("-")])]):_vm._e(),_vm._l((_vm.keys),function(key,index){return _c('th',{key:("header-" + index),staticClass:"hidden h-8 cursor-pointer select-none py-1 px-6 text-left text-200 font-semibold sm:table-cell",class:{
          'font-bold': key === _vm.sort.replace('-', '')
        },on:{"click":function($event){return _vm.sortClicked(key)}}},[_c('div',{staticClass:"flex flex-row items-center justify-start"},[_c('span',[_vm._v(_vm._s(_vm._f("prettykey")(key)))]),(key === _vm.sort.replace('-', ''))?_c('IconChevron',{class:{
              'rotate-180 transform': _vm.sort.indexOf('-') === 0
            }}):_vm._e()],1)])})],2)]),_c('tbody',{staticClass:"text-neutral-900"},[(!_vm.data || _vm.data.length === 0)?_c('tr',[_c('td',{staticClass:"transition py-3 px-6 text-left text-neutral-700 duration-150 ease-in-out group-hover:bg-neutral-200"},[_vm._v(" "+_vm._s(_vm.$t('DataTable.NoResults'))+" ")])]):_vm._e(),_vm._l((_vm.data),function(item,index){return _c('tr',{key:("row-" + index),staticClass:"border-b group cursor-pointer border-neutral-50 odd:bg-neutral-50 even:bg-neutral-0",class:{
        'opacity-50 pointer-events-none': item._disabled
      },on:{"click":function($event){return _vm.rowClicked(item, $event)}}},_vm._l((_vm.keys),function(key){return _c('td',{key:("value-" + key),staticClass:"transition block py-3 px-6 text-left duration-150 ease-in-out group-hover:bg-neutral-200 sm:table-cell max-w-3xl truncate",class:{
          'w-16': key === 'entity'
        }},[_c('span',{staticClass:"block font-bold sm:hidden"},[_vm._v(_vm._s(_vm._f("prettykey")(key)))]),(key === 'entity')?_c('div',{staticClass:"flex w-full flex-row gap-x-2"},[_c('EntityIcon',{attrs:{"entity":item[key]}}),(item.entity_payment_status)?_c('PaymentStatusIcon',{attrs:{"status":item.entity_payment_status}}):_vm._e()],1):(key === 'payment_status')?_c('PaymentStatusIcon',{attrs:{"status":item[key]}}):(key === 'title')?_c('span',{staticClass:"w-full"},[_vm._v(_vm._s(item[key]))]):(
            key.includes('amount') || key.includes('price') || key.includes('cost') || key.includes('income')
          )?_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm._f("money")((item[key] * 100))))]):(key.includes('date'))?_c('span',[_vm._v(_vm._s(_vm._f("date")(item[key],_vm.dateFormat)))]):(key.includes('time') && !isNaN(item[key]))?_c('span',[_vm._v(_vm._s(Number(item[key]).toFixed(2))+" minutes")]):((key.includes('_seconds') || key.includes('duration')) && !isNaN(item[key]))?_c('span',{class:{
            'font-bold text-red-700': key.includes('check') && item[key] > 86400
          }},[_vm._v(_vm._s(_vm._f("duration")(item[key],2))+"s")]):_c('span',[_vm._v(_vm._s(item[key]))])],1)}),0)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }