<template>
  <div class="flex flex-col items-center justify-start bg-neutral-0">
    <div class="mt-2 flex justify-center rounded-md bg-neutral-900 p-2 md:mt-16">
      <img src="@/assets/images/logo.svg" alt="Triple8 Admin" />
    </div>
    <form @submit.prevent="login" class="mx-auto mt-8 w-full max-w-md">
      <div class="border grid gap-5 rounded-md border-neutral-50 bg-white p-8 py-12">
        <div class="grid gap-3">
          <InputElement v-model="email" type="email" :placeholder="$t('Forms.Email')" />
          <InputElement v-model="password" type="password" :placeholder="$t('Forms.Password')" />
        </div>

        <span v-if="error" class="rounded-md bg-secondary-700 px-4 py-1 text-200 text-white">{{ error }}</span>

        <ButtonElement
          type="submit"
          :disabled="loading"
          :text="$t('Login.Action.Login')"
          variant="secondary"
          size="md"
        />
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      error: ''
    };
  },
  methods: {
    async login() {
      if (this.email.length === 0 || this.password.length === 0) {
        this.error = this.$t('Login.Error.EmptyFields');
        return;
      }

      this.loading = true;
      this.error = '';

      try {
        const response = await axios.post(`${this.$store.getters.apiBaseURL}/auth/login`, {
          username: this.email,
          password: this.password
        });

        this.loading = false;
        const jwt = _.get(response, 'data.jwt', '') || '';
        const user = _.get(response, 'data.user', null);

        if (jwt.length === 0 || !user) {
          throw new Error('Invalid data');
        }

        if (user.role !== 'ROLE_SUPER_ADMIN') {
          this.error = this.$t('Login.Error.NoAdminRights');
          return;
        }

        this.$token.saveToken(jwt);
        this.$store.commit('jwt', jwt);
        this.$store.commit('user', user);
        this.$router.push({ name: 'Dashboard' });
      } catch (e) {
        // Invalid
        this.loading = false;
        this.error = _.get(e, 'response.data.error.message', 'Unknown error');
      }
    }
  }
};
</script>
