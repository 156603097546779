import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const languageParts = navigator.language.split('-');

const requireModule = require.context('@/assets/locale', false, /\.json$/);
const messages = {};

requireModule.keys().forEach((fileName) => {
  const languageIdentifier = fileName.replace(/(\.\/|\.json)/g, '');
  messages[languageIdentifier] = requireModule(fileName);
});

const i18n = new VueI18n({
  locale: languageParts[0],
  fallbackLocale: 'en',
  messages
});
export default i18n;
