<template>
  <div>
    <div ref="container" class="relative w-full overflow-hidden">
      <span class="invisible"><slot></slot></span>
      <span
        ref="text"
        class="whitespace-no-wrap absolute left-0 transition-transform duration-1000 ease-in-out"
        :style="{ transform: `translate(${offset}px, 0)` }"
        ><slot></slot
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollerSpan',
  data() {
    return {
      intervalReference: null,
      offset: 0
    };
  },
  mounted() {
    this.startScrolling();
  },
  methods: {
    startScrolling() {
      const containerWidth = this.$refs.container.getBoundingClientRect().width;
      const elementWidth = this.$refs.text.getBoundingClientRect().width;

      if (containerWidth >= elementWidth) {
        this.stopScrolling();
        return;
      }

      this.intervalReference = setInterval(() => {
        this.performScroll();
      }, 2000);

      setTimeout(() => {
        this.performScroll();
      }, 1000);
    },
    stopScrolling() {
      this.offset = 0;

      if (!this.intervalReference) {
        return;
      }

      clearInterval(this.intervalReference);
      this.intervalReference = null;
    },
    performScroll() {
      if (!this.$refs.container || !this.$refs.text) {
        this.stopScrolling();
        return;
      }

      const containerWidth = this.$refs.container.getBoundingClientRect().width;
      const elementWidth = this.$refs.text.getBoundingClientRect().width;

      if (containerWidth >= elementWidth) {
        this.stopScrolling();
        return;
      }

      if (this.offset !== 0) {
        this.offset = 0;
      } else {
        this.offset = -(elementWidth - containerWidth);
      }
    }
  }
};
</script>
