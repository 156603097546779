export default function _(value, ...args) {
  if (!value) {
    return '';
  }

  if (Array.isArray(args[0])) {
    [args] = args;
  }

  return value.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}
