<template>
  <div style="width: 154px; height: 118px;" class="relative animation-blob-morph animation-infinite animation-alternate animation-5000">
    <svg width="154" height="118" xmlns="http://www.w3.org/2000/svg"><path d="M53.672 104.804c-7.759.136-12.531-4.89-16.12-8.601-4.835-4.998-3.557-10.49-8.718-13.095-5.161-2.606-11.045-2.805-16.402-4.803-5.009-1.868-8.947-5.627-10.74-10.81-1.95-5.634-.529-11.676 2.047-16.847 4.396-8.822 16.754-8.505 19.462-15.694C26.3 26.729 30.696-7.07 72.806 2.267c17.831 3.953 19.387 20.535 31.016 20.54 11.628.003 20.804-3.905 26.315-5.398 5.153-1.395 10.495-.615 14.882 2.584 4.769 3.48 7.008 9.26 7.81 15.003 1.37 9.798.45 18.975-5.626 26.584-4.199 5.26-11.926 8.97-14.2 10.506-3.772 2.55-6.065 6.382-7.425 10.72-2.673 8.526-2.334 18.852-9.553 25.114-9.094 7.888-27.325 14.676-33.125 2.72-5.04-10.39-12.115-9.566-12.389-9.675-6.201.788-11.036 3.738-16.839 3.84v-.001z" fill="currentColor" fill-rule="nonzero"/></svg>
    <!--div class="absolute bg-red rounded-full inset-0 animation-blob-part-morph animation-infinite animation-alternate animation-3000"></div-->
  </div>
</template>

<script>
export default {
  name: 'IconBlob',
  props: {
    animating: {
      type: Boolean,
      default: true,
      required: false
    }
  }
};

/*.blob {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:10;
  margin-top:8px;
  animation:scaleBlob 5s infinite;
  -webkit-filter: url("#goo");
  filter: url("#goo");
}
.blob div {
  background: #F7F8F9;
  border-radius: 99em;
  position: absolute;
  top:10%;
  left:10%;
  right:10%;
  bottom:10%;
}
.blob div:nth-child(1) {
  animation: blob-1 5s ease-in-out infinite;
  animation-delay: 0s;
  top:-10%;
  left:25%;
  right:25%;
}
.blob div:nth-child(2) {
  animation: blob-2 5s ease-in-out infinite;
  animation-delay: 1s;
}
.blob div:nth-child(3) {
  animation: blob-3 5s ease-in-out infinite;
  animation-delay: 3s;
}
.blob div:nth-child(4) {
  animation: blob-4 5s ease-in-out infinite;
  animation-delay: 3s;
}
.blob div:nth-child(5) {
  animation: blob-5 5s ease-in-out infinite;
  animation-delay: 1s;
}


.lottie-blob {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 160%;
  height: 160%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    top: 50%;
    width: 140%;
    height: 140%;
  }

  &--white {
    width: 120%;
    height: 120%;
    top: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 100%;
    }
  }

  &--prices {
    top: 50%;
    animation: fadeIn .4s ease .4s forwards;
    opacity: 0;
    transform: translate(-50%, -50%) scaleX(1.3);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@keyframes scaleBlob {
  0% {
    transform:scaleY(1)scaleX(1)translateZ(0);
  }
  50% {
    transform:scaleY(1.2)scaleX(1.1)translateZ(0);
  }
  100% {
    transform:scaleY(1)scaleX(1)translateZ(0);
  }
}

@keyframes blob-1 {
  50% {
    transform: translate3d(0, -10px, 0) scale(.9, 1.1);
  }
}
@keyframes blob-2 {
  50% {
    transform: translate3d(-30px, 0, 0) scale(1, 1.2);
  }
}
@keyframes blob-3 {
  50% {
    transform: translate3d(-16px, -20px, 0);
  }
}
@keyframes blob-4 {
  50% {
    transform: translate3d(20px, -13px, 0);
  }
}
@keyframes blob-5 {
  50% {
    transform: translate3d(27px, 16px, 0);
  }
}

@keyframes cloud-1 {
  50% {
    transform: translate3d(27px, 16px, 0);
  }
}*/
</script>