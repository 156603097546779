<template>
  <div
    class="transition border relative flex items-center rounded-md border-neutral-50 bg-white duration-300 ease-in-out"
  >
    <div class="flex-shrink w-full">
      <input
        ref="inputElement"
        class="focus:outline-none block h-10 w-full appearance-none bg-transparent px-4 font-medium placeholder-neutral-300 focus:z-10"
        :type="type"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        @input="updateValue($event.target.value)"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InputElement',
  props: {
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'text',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },

    focus() {
      this.$refs.inputElement.focus();
    }
  }
};
</script>
