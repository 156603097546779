import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';

export default function _(value, alwaysRelative = false) {
  if (value === null || value === undefined) {
    return null;
  }

  let date = dayjs(value);

  if (date.isAfter(dayjs())) {
    date = dayjs();
  }

  if (!alwaysRelative && date.isBefore(dayjs().subtract(2, 'day'))) {
    return dayjs(value).locale('nl').format('DD MMMM YYYY');
  }

  dayjs.extend(relativeTime);
  return date.locale('nl').fromNow();
}
