var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'group translate-z-0 rounded outline-none focus:outline-none relative inline-flex transform select-none appearance-none items-center justify-center overflow-hidden font-semibold tracking-tight transition-all duration-200',
    // sizes
    { 'h-8 px-4': _vm.size === 'sm' && _vm.variant != 'minimal' && !_vm.$slots.iconCenter },
    { 'h-10 px-4': _vm.size === 'md' && _vm.variant != 'minimal' && !_vm.$slots.iconCenter },
    { 'h-14 px-6': _vm.size === 'lg' && _vm.variant != 'minimal' && !_vm.$slots.iconCenter },

    { 'h-8 h-8': _vm.size === 'sm' && _vm.$slots.iconCenter },
    { 'h-10 w-10': _vm.size === 'md' && _vm.$slots.iconCenter },
    { 'w-14 h-14': _vm.size === 'lg' && _vm.$slots.iconCenter },

    { 'text-300': _vm.size === 'sm' },
    { 'text-300': _vm.size === 'md' },
    { 'text-400': _vm.size === 'lg' },

    // variants
    { 'bg-primary text-white': _vm.variant === 'primary' },
    { 'bg-secondary text-white': _vm.variant === 'secondary' },
    { 'bg-tertiary text-tertiary-900': _vm.variant === 'tertiary' },
    { 'bg-tertiary-600 text-tertiary-900': _vm.variant === 'tertiary-600' },
    { 'text-neutral-700 hover:text-neutral-900': _vm.variant === 'minimal' && !_vm.colorizeText },
    { 'text-primary-800 hover:text-primary-900': _vm.variant === 'minimal' && _vm.colorizeText },
    { 'border border-neutral-100 text-neutral-700 hover:border-neutral-300': _vm.variant === 'outline' },
    { 'pointer-events-none opacity-50': _vm.disabled },

    // full-width
    { 'w-full': _vm.fullWidth === true }
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.variant != 'minimal' && _vm.variant != 'outline')?_c('div',{class:[
      'absolute inset-0 origin-top scale-y-0 transform bg-green-500 transition-transform duration-300 group-hover:origin-bottom group-hover:scale-y-100',
      // sizes
      { 'bg-primary-800': _vm.variant === 'primary' },
      { 'bg-secondary-800': _vm.variant === 'secondary' },
      { 'bg-tertiary-600': _vm.variant === 'tertiary' },
      { 'bg-tertiary-800': _vm.variant === 'tertiary-600' }
    ]}):_vm._e(),(_vm.$slots.iconLeft)?_c('div',{class:[
      'relative -ml-1 flex-shrink-0',
      // sizes
      { 'mr-2': _vm.size === 'sm' || _vm.size === 'md' },
      { 'mr-4': _vm.size === 'lg' },
      // variants
      { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': _vm.variant === 'outline' }
    ]},[_vm._t("iconLeft")],2):_vm._e(),(_vm.hasText)?_c('div',{staticClass:"relative",class:{ truncate: _vm.text.length > 5 }},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.$slots.iconCenter)?_c('div',{class:[
      'relative',
      { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': _vm.variant === 'outline' }
    ]},[_vm._t("iconCenter")],2):_vm._e(),(_vm.$slots.iconRight)?_c('div',{class:[
      'relative -mr-1 flex-shrink-0',
      // sizes
      { 'ml-2': _vm.size === 'sm' || _vm.size === 'md' },
      { 'ml-4': _vm.size === 'lg' },
      // variants
      { 'text-neutral-300 transition-all duration-200 group-hover:text-neutral-500': _vm.variant === 'outline' }
    ]},[_vm._t("iconRight")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }