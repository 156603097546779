const GuardInactiveSession = (onFailRouterOptions) => {
  return async (to, from, next) => {
    if (!window.rootVue) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 300);
      });
    }

    if (window.rootVue.$store.state.jwt && window.rootVue.$store.state.user) {
      next(onFailRouterOptions);
      return;
    }
    next();
  };
};

export default GuardInactiveSession;
