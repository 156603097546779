<template>
  <BaseModal :title="$t('Login.TODO')" v-bind="$props" :withBorder="true" width="md" @backdrop-clicked="hide">
    <template v-slot:icon>
      <div class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-neutral-50 text-neutral-900">
        <IconUser />
      </div>
    </template>
    <template>
      <form class="flex w-full flex-col" @submit.prevent="login">
        <span class="my-4">{{ $t('Login.TODO') }}</span>
        <InputElement type="text" :placeholder="$t('Login.TODO')" v-model="username" />
        <InputElement type="password" :placeholder="$t('Login.TODO')" v-model="password" />
        <input type="submit" class="h-px w-px bg-white" value="" />
      </form>
    </template>

    <template v-slot:bottom>
      <ButtonElement class="flex-shrink-0" variant="outline" size="md" :text="$t('Login.TODO')" @click="hide" />
      <ButtonElement class="flex-shrink-0" variant="primary" size="md" :text="$t('Login.TODO')" @click="login" />
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'LoginModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  },
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${this.$store.getters.apiBaseURL}/auth/login`, {
          username: this.username,
          password: this.password
        });
        if (!response || !response.data || !response.data.user) {
          // TODO: Show error
          return;
        }

        // response.data.jwt
        if (response.data.user) {
          this.$store.commit('user', response.data.user);
          this.$store.commit('jwt', response.data.jwt);
          this.hide();
        }
      } catch (e) {
        // TODO: Error while getting statuses
      }
    }
  }
};
</script>
