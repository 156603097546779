<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Finance.Videos') }}</h1>
      <DateSelector v-model="$app.startDate" />
      <DateSelector v-model="$app.endDate" />
    </div>

    <div v-if="stats" class="mt-16 flex w-full flex-col items-center justify-start">
      <div
        class="border flex w-full max-w-3xl flex-col items-start justify-center rounded-md border-neutral-50 bg-white py-4 sm:flex-row"
      >
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.Uploads') }}</span>
          <span>{{ stats.total.count }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.Unpaid') }}</span>
          <span>{{ stats.total.unpaid }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.Revenue') }}</span>
          <span>{{ (stats.total.revenue * 100) | money }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.RevenueComfort') }}</span>
          <span>{{ (stats.total.revenue_comfort * 100) | money }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.VideoMinutes') }}</span>
          <span>{{ (stats.total.minutes.video * 60) | duration(1) }}</span>
          <span>{{ Math.floor(stats.total.minutes.video / 60) }} hrs</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.Stats.ComfortMinutes') }}</span>
          <span>{{ (stats.total.minutes.comfort * 60) | duration(1) }}</span>
          <span>{{ Math.floor(stats.total.minutes.comfort / 60) }} hrs</span>
        </div>
      </div>
      <div class="mt-8 grid w-full max-w-3xl grid-cols-1 gap-8 sm:grid-cols-2">
        <div
          v-for="(videostat, key) of stats.videos"
          :key="key"
          class="border flex flex-col items-start justify-center rounded-md border-neutral-50 bg-white px-8 py-4"
          :class="{
            'col-span-2':
              Object.keys(stats.videos).length % 2 == 1 &&
              Object.keys(stats.videos).indexOf(key) === Object.keys(stats.videos).length - 1
          }"
        >
          <div class="mb-4 flex w-full items-center justify-between text-300">
            <span class="font-semibold">{{ videostat.title }}</span>
            <div class="text-100 text-neutral-700">
              <span>Video {{ (videostat.price.video * 100) | money }} p/m</span>
              <span v-if="videostat.price.comfort >= 0"> | </span>
              <span v-if="videostat.price.comfort >= 0">Comfort {{ (videostat.price.comfort * 100) | money }} p/m</span>
            </div>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Video.Stats.Uploads') }}</span>
            <span>{{ videostat.count }}</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Video.Stats.Unpaid') }}</span>
            <span>{{ videostat.unpaid }}</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Video.Stats.Revenue') }}</span>
            <span>{{ (videostat.revenue * 100) | money }}</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Video.Stats.VideoMinutes') }}</span>
            <span>{{ (videostat.minutes.video * 60) | duration(1) }}</span>
          </div>
          <div class="flex w-full items-center justify-between text-300">
            <span class="text-neutral-700">{{ $t('Video.Stats.ComfortMinutes') }}</span>
            <span>{{ (videostat.minutes.comfort * 60) | duration(1) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceVideos',
  data() {
    return {
      stats: null
    };
  },
  watch: {
    '$app.startDate': {
      handler() {
        this.refresh();
      }
    },
    '$app.endDate': {
      handler() {
        this.refresh();
      }
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.stats = await this.$api.get(
        `/admin/finance/videos?start=${this.$app.startDate.getTime() / 1000}&end=${this.$app.endDate.getTime() / 1000}`
      );
    }
  }
};
</script>
