import Vue from 'vue';
import VueFlags from '@growthbunker/vueflags';
import HighchartsVue from 'highcharts-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueCookies from 'vue-cookies';

import env from './env';
import api from './api';
import store from './store';
import router from './router';
import i18n from './i18n';
import modalsController from './modalscontroller';
import helper from './helper';
import token from './token';
import './filters';

import App from './App.vue';
import './registerServiceWorker';
import './registerGlobalComponents';

// Import TailwindCSS
import './assets/styles/tailwind.css';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(HighchartsVue);

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

Vue.directive('scroll', {
  inserted: (el, binding) => {
    const f = (evt) => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  }
});

Vue.use(VueFlags, {
  iconPath: '/img/flags/'
});

window.rootVue = new Vue({
  env,
  api,
  store,
  router,
  token,
  i18n,
  modalsController,
  helper,
  render: (h) => h(App)
}).$mount('#app');
