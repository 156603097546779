<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <h1 class="text-700 font-bold">{{ $t('Menu.Finance.Videos') }}</h1>
    </div>

    <div class="mt-16 flex w-full flex-col gap-y-16">
      <highcharts class="w-full" :options="chartOptionsVideos"></highcharts>
      <highcharts class="w-full" :options="chartOptionsVideosByLanguage"></highcharts>
      <highcharts class="w-full" :options="chartOptionsRevenue"></highcharts>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'FinanceTimeline',
  components: {
    highcharts: Chart
  },
  data() {
    return {
      chartOptionsVideos: {
        title: {
          text: 'Videos'
        },
        chart: {
          backgroundColor: null
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter() {
              const date = new Date(this.value);
              return date.toLocaleString('default', { month: 'long', year: 'numeric' });
            }
          }
        },
        yAxis: {
          type: 'number'
        },
        series: []
      },
      chartOptionsVideosByLanguage: {
        title: {
          text: 'Videos by language'
        },
        chart: {
          backgroundColor: null
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter() {
              const date = new Date(this.value);
              return date.toLocaleString('default', { month: 'long', year: 'numeric' });
            }
          }
        },
        yAxis: {
          type: 'number'
        },
        series: []
      },
      chartOptionsRevenue: {
        title: {
          text: 'Income'
        },
        chart: {
          backgroundColor: null
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter() {
              const date = new Date(this.value);
              return date.toLocaleString('default', { month: 'long', year: 'numeric' });
            }
          }
        },
        yAxis: {
          type: 'number'
        },
        series: []
      }
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.chartOptionsVideos.series = [
        {
          type: 'area',
          name: 'Uploads',
          color: '#93CFC9',
          data: []
        },
        {
          type: 'area',
          name: 'Minutes',
          color: '#DC6A69',
          data: []
        },
        {
          type: 'area',
          name: 'Minutes (Free)',
          color: '#FCCA4F',
          data: []
        },
        {
          type: 'area',
          name: 'Minutes (Premium)',
          color: '#665120',
          data: []
        },
        {
          type: 'area',
          name: 'Minutes (Professional)',
          color: '#FFEAB9',
          data: []
        }
      ];

      this.chartOptionsVideosByLanguage.series = [
        {
          type: 'area',
          name: 'nl-NL',
          color: '#93CFC9',
          data: []
        },
        {
          type: 'area',
          name: 'en-US',
          color: '#DC6A69',
          data: []
        },
        {
          type: 'area',
          name: 'de-DE',
          color: '#FCCA4F',
          data: []
        },
        {
          type: 'area',
          name: 'es-ES',
          color: '#665120',
          data: []
        },
        {
          type: 'area',
          name: 'ar-AR',
          color: '#FFEAB9',
          data: []
        },
        {
          type: 'area',
          name: 'Other',
          color: '#CCCCCC',
          data: []
        }
      ];

      this.chartOptionsRevenue.series = [
        {
          type: 'area',
          name: 'All',
          color: '#93CFC9',
          data: []
        },
        {
          type: 'area',
          name: 'Videos (Premium)',
          color: '#DC6A69',
          data: []
        },
        {
          type: 'area',
          name: 'Comfort',
          color: '#665120',
          data: []
        },
        {
          type: 'area',
          name: 'Organizations',
          color: '#FCCA4F',
          data: []
        }
      ];

      const months = 12;
      for (let i = 1; i <= months; i += 1) {
        const date = new Date();
        date.setUTCHours(5);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setDate(1);
        date.setMonth(date.getMonth() - i);
        this.plotDataForMonth(date);
      }
    },

    async plotDataForMonth(date) {
      const endDate = new Date(date.getTime());
      endDate.setMonth(endDate.getMonth() + 1);

      const stats = await this.$api.get(
        `/admin/finance/videos?start=${date.getTime() / 1000}&end=${endDate.getTime() / 1000}`
      );

      const statsByLanguage = await this.$api.get(
        `/admin/finance/videos/language?start=${date.getTime() / 1000}&end=${endDate.getTime() / 1000}`
      );

      for (let i = 0; i < statsByLanguage.length; i += 1) {
        const stat = statsByLanguage[i];
        const item = this.chartOptionsVideosByLanguage.series.find((s) => s.name === stat.language);
        let index = this.chartOptionsVideosByLanguage.series.indexOf(item);
        if (index <= -1) {
          index = this.chartOptionsVideosByLanguage.series.length - 1;
        }
        this.chartOptionsVideosByLanguage.series[index].data.push({ x: date.getTime(), y: stat.count });
      }

      const organizationsStats = await this.$api.get(
        `/admin/finance/organizations?start=${date.getTime() / 1000}&end=${endDate.getTime() / 1000}`
      );

      this.chartOptionsVideos.series[0].data.push({ x: date.getTime(), y: stats.total.count });
      this.chartOptionsVideos.series[1].data.push({ x: date.getTime(), y: stats.total.minutes.video });

      const identifiers = Object.keys(stats.videos);
      for (let i = 0; i < identifiers.length; i += 1) {
        const planId = Number(identifiers[i]);
        const stat = stats.videos[planId];

        let target = 0;
        switch (planId) {
          case 1:
            target = 0;
            break;

          case 3:
          case 4:
            target = 1;
            break;

          case 7:
            target = 2;
            break;

          default:
          case 6:
            target = -1;
            break;
        }

        if (target < 0) {
          continue;
        }

        this.chartOptionsVideos.series[2 + target].data.push({ x: date.getTime(), y: stat.minutes.video });
      }

      const keys = Object.keys(organizationsStats.selected_period.revenue);
      let organizationsRevenue = 0;
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        organizationsRevenue += organizationsStats.selected_period.revenue[key].revenue;
      }

      this.chartOptionsRevenue.series[0].data.push({
        x: date.getTime(),
        y: organizationsRevenue + stats.total.revenue
      });
      this.chartOptionsRevenue.series[1].data.push({ x: date.getTime(), y: stats.total.revenue });
      this.chartOptionsRevenue.series[2].data.push({ x: date.getTime(), y: stats.total.revenue_comfort });
      this.chartOptionsRevenue.series[3].data.push({ x: date.getTime(), y: organizationsRevenue });
    }
  }
};
</script>
