<template>
  <div class="flex flex-col">
    <div class="flex">
      <div
        v-for="(title, index) of titles"
        :key="index"
        @click="selectedTab = index + 1"
        class="border border-neutral-50 rounded-t-lg cursor-pointer px-2"
        :class="{
          'bg-neutral-50': selectedTab === index + 1
        }"
      >
        <span class="font-bold text-200">{{ title }}</span>
      </div>
    </div>

    <slot :name="`tab${selectedTab}`" />
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTab: 1
    };
  }
};
</script>
