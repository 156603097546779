<template>
  <div class="border flex w-full rounded-md border-neutral-50 bg-white py-4 px-8">
    <div class="flex flex-row items-center justify-between">
      <span v-if="title" class="mb-4 flex w-full font-semibold">
        {{ title }}
      </span>
    </div>
    <slot class="flex flex-row" name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    }
  }
};
</script>
