<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full flex-col items-start justify-start">
      <h1 class="text-700 font-bold">{{ $t('Menu.Finance.Upcoming') }}</h1>
      <div
        class="border m-auto mb-8 flex w-full max-w-3xl flex-row items-center justify-center rounded-md border-neutral-50 bg-white py-4"
      >
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Finance.Upcoming.ProjectedIncome') }}</span>
          <span v-for="(value, key) of projectedPlanNameAndPrices" :key="key">{{ key }}: &euro;{{ value }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Finance.Upcoming.ProjectedTotal') }}</span>
          <span>&euro; {{ projectedTotal }}</span>
        </div>
      </div>
    </div>

    <div class="mt-16 flex w-full flex-col">
      <DataTable :data="upcoming" @rowClicked="rowClicked" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceUpcoming',
  data() {
    return {
      upcoming: [],
      projectedPlanNameAndPrices: {},
      projectedTotal: 0
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const data = ((await this.$api.get('/admin/finance/upcoming')) || []).map((info) => {
        const now = new Date();
        const expiryDate = new Date(info.organization_plan_expiry_date);
        const deltaDays = Math.ceil((expiryDate.getTime() - now.getTime()) / 1000 / 86400);
        info.invoiced_in = deltaDays < 0 ? `${Math.abs(deltaDays)} days ago` : `in ${deltaDays} day(s)`;
        info.payment_status = deltaDays <= 0 ? 'sent' : 'pending';

        if (info.organization_plan_price > 0) {
          this.projectedPlanNameAndPrices[info.organization_plan_name] =
            (this.projectedPlanNameAndPrices[info.organization_plan_name] || 0) + info.organization_plan_price;

          this.projectedTotal += Number(info.organization_plan_price);
        }

        delete info.organization_plan_price;
        return info;
      });
      this.upcoming = data;
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      const routeData = { name: 'Organization', params: { organizationID: item.id } };

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
