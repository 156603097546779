export default function _(value, defaultValue = '') {
  if (value === null || value === undefined) {
    return defaultValue;
  }

  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string' && value.length === 0) {
    return defaultValue;
  }

  return value;
}
