<template>
  <div v-if="visible">
    <div
      @click="backdropClicked"
      class="fixed left-0 top-0 z-20 flex h-full w-full animate-opacity-in items-center justify-center overflow-hidden bg-neutral-500 bg-opacity-50"
      :class="{
        'pointer-events-none animate-opacity-out': displayHideAnimation
      }"
    ></div>
    <div
      @click.self="backdropClicked"
      class="fixed left-0 top-0 z-20 flex h-full w-full animate-modal-dialog-visible items-center justify-center overflow-hidden"
      :class="{
        'pointer-events-none animate-modal-dialog-invisible': displayHideAnimation
      }"
    >
      <div
        class="mx-4 flex w-full min-w-modal flex-col items-start justify-start overflow-hidden rounded-lg bg-white text-400 font-normal text-neutral-700 opacity-100 shadow-xl xl:mx-0"
        :class="{
          [`max-w-${width}`]: true
        }"
      >
        <div class="w-full">
          <slot name="top">
            <div
              class="mt-4 flex w-full flex-row items-center justify-between px-6"
              :class="{
                'border-b border-neutral-50 pb-2': withBorder
              }"
            >
              <div
                v-if="title"
                class="flex flex-shrink-0 flex-row items-center justify-start py-2"
                :class="{
                  'space-x-4': !withIconMargin,
                  'space-x-6': withIconMargin
                }"
              >
                <slot name="icon"></slot>
                <span class="text-500 font-bold text-neutral">{{ title }}</span>
              </div>
              <div class="flex-grow w-full"></div>
              <IconClose class="flex-shrink-0 cursor-pointer text-neutral-900" @click.native="hide" />
            </div>
          </slot>
          <div
            class="w-full px-6 pb-6"
            :class="{
              'pt-4': withBorder,
              'pt-0': !withBorder,
              'max-h-screen-half overflow-scroll': !disableScroll
            }"
          >
            <div class="flex flex-row">
              <div v-if="withIconMargin" class="invisible mr-6 flex-shrink-0">
                <slot name="icon" />
              </div>
              <slot></slot>
            </div>
          </div>
          <div
            v-if="$slots.bottom"
            class="flex w-full flex-row items-center justify-end space-x-4 px-6"
            :class="{
              'border-t border-neutral-50 bg-neutral-0 py-4': withBorder,
              'mb-6': !withBorder
            }"
          >
            <slot name="bottom"></slot>
          </div>
        </div>
        <div
          v-if="special"
          class="relative flex h-4 w-full flex-col items-center justify-center overflow-hidden bg-secondary"
        >
          <div class="flex w-full flex-row">
            <div class="h-4 flex-1 bg-secondary"></div>
            <div class="h-4 flex-1 bg-primary"></div>
          </div>
          <div class="absolute -mt-2 h-40 w-40 origin-center rotate-45 transform bg-tertiary"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    disableScroll: {
      type: Boolean,
      default: false,
      required: false
    },
    withIconMargin: {
      type: Boolean,
      default: false,
      required: false
    },
    withBorder: {
      type: Boolean,
      default: false,
      required: false
    },
    special: {
      type: Boolean,
      default: false,
      required: false
    },
    width: {
      type: String,
      default: '2xl',
      required: false
    }
  },
  data() {
    return {
      visible: false,
      displayHideAnimation: false
    };
  },
  methods: {
    show() {
      this.visible = true;
      this.$children.forEach((child) => {
        child.visible = true;
      });

      this.$emit('didshow');
    },

    hide() {
      this.displayHideAnimation = true;
      this.$children.forEach((child) => {
        child.displayHideAnimation = true;
      });

      setTimeout(() => {
        this.visible = false;
        this.displayHideAnimation = false;
        this.$children.forEach((child) => {
          child.visible = false;
          child.displayHideAnimation = false;
        });

        this.$emit('didhide');
      }, 300);
    },

    backdropClicked() {
      this.$emit('backdrop-clicked');
    }
  }
};
</script>
