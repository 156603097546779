<template>
  <BaseModal title="" :disableScroll="true" :special="true" width="md" v-bind="$props" @backdrop-clicked="hide">
    <template>
      <div class="flex flex-col items-center justify-start px-16 text-center text-400 text-neutral-700">
        <div class="relative flex items-center justify-center">
          <div class="absolute z-10 flex animate-premium-overscale items-center justify-center">
            <IconPremiumBackground class="animate-spin-slow absolute" />
            <IconPremium class="absolute animate-premium-star" />
          </div>
          <Animation name="premium" />
        </div>
        <span class="mt-8 mb-2 text-400 font-bold text-neutral-900 xl:text-600">{{
          $t('Upload.Alert.InfoUpgradePlan.Title')
        }}</span>
        <span class="mb-4 hidden xl:block">{{ $t('Upload.Alert.InfoUpgradePlan.Subtitle') }}</span>
        <ul class="mb-8 text-100 font-medium text-neutral-500 xl:text-400">
          <li
            v-for="(line, index) in $t('Upload.Alert.InfoUpgradePlan.Body').split('\n')"
            :key="index"
            class="flex flex-row items-center justify-start space-x-2 text-left"
          >
            <IconCheck class="flex-shrink-0 text-primary" />
            <span>{{ line }}</span>
          </li>
        </ul>

        <ButtonElement
          class="flex-shrink-0"
          variant="minimal"
          size="sm"
          :text="$t('Upload.Alert.InfoUpgradePlan.Action.Cancel')"
          @click="hide"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'PremiumPlanModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  }
};
</script>
