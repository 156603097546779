<template>
  <BaseModal title="" v-bind="$props" width="sm" @backdrop-clicked="hide">
    <template>
      <div class="flex flex-col items-center justify-start">
        <div v-if="icon" class="border mb-6 rounded-full border-neutral-50 p-2">
          <component :is="icon" />
        </div>
        <span class="mb-2 text-center text-500 font-bold text-neutral">{{ title }}</span>
        <span class="mx-4 max-w-lg text-center">{{ body }}</span>
      </div>
    </template>

    <template v-slot:bottom>
      <ButtonElement variant="outline" :fullWidth="true" size="md" :text="dismiss" @click="hide" />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'NoteModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  },
  props: {
    body: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: '',
      required: false
    },
    dismiss: {
      type: String,
      required: true
    }
  }
};
</script>
