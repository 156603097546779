var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',[_c('div',{staticClass:"fixed left-0 top-0 z-20 flex h-full w-full animate-opacity-in items-center justify-center overflow-hidden bg-neutral-500 bg-opacity-50",class:{
      'pointer-events-none animate-opacity-out': _vm.displayHideAnimation
    },on:{"click":_vm.backdropClicked}}),_c('div',{staticClass:"fixed left-0 top-0 z-20 flex h-full w-full animate-modal-dialog-visible items-center justify-center overflow-hidden",class:{
      'pointer-events-none animate-modal-dialog-invisible': _vm.displayHideAnimation
    },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.backdropClicked.apply(null, arguments)}}},[_c('div',{staticClass:"mx-4 flex w-full min-w-modal flex-col items-start justify-start overflow-hidden rounded-lg bg-white text-400 font-normal text-neutral-700 opacity-100 shadow-xl xl:mx-0",class:( _obj = {}, _obj[("max-w-" + _vm.width)] = true, _obj )},[_c('div',{staticClass:"w-full"},[_vm._t("top",function(){return [_c('div',{staticClass:"mt-4 flex w-full flex-row items-center justify-between px-6",class:{
              'border-b border-neutral-50 pb-2': _vm.withBorder
            }},[(_vm.title)?_c('div',{staticClass:"flex flex-shrink-0 flex-row items-center justify-start py-2",class:{
                'space-x-4': !_vm.withIconMargin,
                'space-x-6': _vm.withIconMargin
              }},[_vm._t("icon"),_c('span',{staticClass:"text-500 font-bold text-neutral"},[_vm._v(_vm._s(_vm.title))])],2):_vm._e(),_c('div',{staticClass:"flex-grow w-full"}),_c('IconClose',{staticClass:"flex-shrink-0 cursor-pointer text-neutral-900",nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}})],1)]}),_c('div',{staticClass:"w-full px-6 pb-6",class:{
            'pt-4': _vm.withBorder,
            'pt-0': !_vm.withBorder,
            'max-h-screen-half overflow-scroll': !_vm.disableScroll
          }},[_c('div',{staticClass:"flex flex-row"},[(_vm.withIconMargin)?_c('div',{staticClass:"invisible mr-6 flex-shrink-0"},[_vm._t("icon")],2):_vm._e(),_vm._t("default")],2)]),(_vm.$slots.bottom)?_c('div',{staticClass:"flex w-full flex-row items-center justify-end space-x-4 px-6",class:{
            'border-t border-neutral-50 bg-neutral-0 py-4': _vm.withBorder,
            'mb-6': !_vm.withBorder
          }},[_vm._t("bottom")],2):_vm._e()],2),(_vm.special)?_c('div',{staticClass:"relative flex h-4 w-full flex-col items-center justify-center overflow-hidden bg-secondary"},[_vm._m(0),_c('div',{staticClass:"absolute -mt-2 h-40 w-40 origin-center rotate-45 transform bg-tertiary"})]):_vm._e()])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-full flex-row"},[_c('div',{staticClass:"h-4 flex-1 bg-secondary"}),_c('div',{staticClass:"h-4 flex-1 bg-primary"})])}]

export { render, staticRenderFns }