<template>
  <BaseModal
    :title="$t('Upload.Topbar.Demo')"
    :disableScroll="true"
    :special="true"
    width="md"
    v-bind="$props"
    @backdrop-clicked="hide"
  >
    <template>
      <form class="flex w-full flex-col" @submit.prevent="requestDemo">
        <span class="my-4">{{ $t('Upload.Alert.Demo.Title') }}</span>
        <InputElement type="email" :placeholder="$t('Upload.StepOrder.EmailAddress')" v-model="email" />
        <input type="submit" class="h-px w-px bg-white" value="" />
        <div
          v-if="errorKey"
          class="animate-slide-x-in rounded-md bg-secondary-800 bg-opacity-25 px-8 py-2 text-center text-300 font-medium text-secondary-800"
        >
          <span>{{ $t(errorKey) }}</span>
        </div>
      </form>
    </template>

    <template v-slot:bottom>
      <ButtonElement
        class="flex-shrink-0"
        variant="primary"
        size="md"
        :text="$t('Upload.Alert.Demo.Action.Confirm')"
        @click="requestDemo"
      />
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import BaseModal from '@/components/modals/BaseModal';

export default {
  name: 'DemoModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal
  },
  data() {
    return {
      email: '',
      errorKey: ''
    };
  },
  computed: {
    isValidEmailAddress() {
      if (!this.email || this.email.length === 0) {
        return false;
      }

      const re =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    }
  },
  methods: {
    async requestDemo() {
      if (!this.isValidEmailAddress) {
        this.errorKey = 'Upload.StepOrder.Error.InvalidEmailAddress';
        return;
      }

      this.errorKey = '';

      try {
        const response = await axios.post(`${this.$store.getters.apiBaseURL}/video/demo`, {
          email: this.email
        });

        this.hide();

        if (response && response.data && response.data.success) {
          window.location = `${this.$store.getters.editorBaseURL}/${response.data.uuid}?editor-premium-demo`;
          /* this.$modal.show('NoteModal', {
            icon: 'IconComplete',
            title: this.$t('Upload.Alert.DemoSent.Title'),
            body: this.$t('Upload.Alert.DemoSent.Body'),
            dismiss: this.$t('Upload.Alert.DemoSent.Action.Cancel')
          }); */
        }
      } catch (e) {
        // TODO: Error while getting statuses
      }
    }
  }
};
</script>
