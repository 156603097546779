var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-7xl m-auto flex w-full flex-col items-start pb-32"},[_c('div',{staticClass:"mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4"},[_c('div',{staticClass:"border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('IconChevronLeft')],1),_c('h1',{staticClass:"text-700 font-bold"},[_vm._v(" "+_vm._s(_vm.$t('Menu.Management.User'))+" "+_vm._s(_vm.item ? _vm.item.username : _vm.$route.params.userID)+" ")]),_c('EntityIcon',{attrs:{"entity":"user"}})],1),_c('div',{staticClass:"mb-4 flex w-full flex-row items-center space-x-4"},[(_vm.item && _vm.item.image_url)?_c('img',{staticClass:"h-16 w-16 rounded-full",attrs:{"src":_vm.item.image_url,"alt":""}}):_vm._e(),_c('div',{staticClass:"flex-grow"}),_c('ButtonElement',{staticClass:"flex-shrink-0",attrs:{"variant":"secondary","size":"md","text":_vm.$t('Management.User.Action.Save')},on:{"click":_vm.saveChanges}}),_c('ButtonElement',{staticClass:"flex-shrink-0",attrs:{"variant":"outline","size":"md","text":_vm.$t('Management.User.Action.OpenActions')},on:{"click":function($event){_vm.actionsVisible = !_vm.actionsVisible}}})],1),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"grid grid-cols-1 flex-col gap-8 pt-8 sm:grid-cols-2"},[_c('Card',{staticClass:"flex-col",attrs:{"title":_vm.$t('Management.User.SectionGeneral')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._l((_vm.generalKeys),function(key){return _c('div',{key:("general-" + key),staticClass:"flex w-full items-start justify-between text-300 even:bg-gray-200",class:{
              'rounded-md bg-tertiary px-2 font-bold': key === 'organization_plan_pay_by_invoice' && _vm.item[key] > 0
            }},[_c('span',{staticClass:"text-neutral-700"},[_vm._v(_vm._s(_vm._f("capitalize")(key.replace(/_/g, ' '))))]),(
                key.indexOf('_date') > -1 ||
                key === 'created' ||
                key === 'updated' ||
                key === 'deleted' ||
                key === 'last_login'
              )?_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.item[key])))]):(key === 'mollie_customer_id')?_c('a',{staticClass:"underline",attrs:{"href":("https://my.mollie.com/dashboard/org_9453701/customers/" + (_vm.item[key])),"target":"_blank"}},[_vm._v(_vm._s(_vm.item[key] || '-'))]):(key === 'organization')?_c('span',{staticClass:"underline cursor-pointer",on:{"click":function($event){return _vm.showOrganization(_vm.item[key].id)}}},[_vm._v(_vm._s(_vm.item[key].name || '-')+" ("+_vm._s(_vm.item[key].id || '-')+")")]):(key === 'role')?_c('div',[_c('span',[_vm._v(_vm._s((_vm.item[key] || '-').replace('ROLE_', '').replace(/_/, ' ').trim()))])]):_c('span',{class:{
                'font-mono text-200': key.indexOf('_id') > 0 || key.indexOf('_uuid') > 0 || key === 'iban'
              }},[_vm._v(_vm._s(_vm.item[key]))])])}),_c('Tabs',{staticClass:"mt-4",attrs:{"titles":[_vm.$t('Video.Stats.Uploads')]},scopedSlots:_vm._u([{key:"tab1",fn:function(){return [(_vm.item && _vm.item.uploads)?_c('div',[_c('DataTable',{attrs:{"data":_vm.item.uploads},on:{"rowClicked":_vm.videoRowClicked}})],1):_vm._e()]},proxy:true}])})]},proxy:true}])}),_c('div',{staticClass:"flex flex-col gap-8"},[(_vm.userKeys)?_c('Card',{staticClass:"flex-col",attrs:{"title":_vm.$t('Management.User.SectionUser')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},_vm._l((_vm.userKeys),function(key){return _c('div',{key:("video-" + key),staticClass:"mb-4 flex flex-col"},[_c('span',{staticClass:"text-300"},[_vm._v(_vm._s(_vm._f("capitalize")(key.replace(/_/g, ' '))))]),_c('InputElement',{attrs:{"type":"text","placeholder":key},model:{value:(_vm.item[key]),callback:function ($$v) {_vm.$set(_vm.item, key, $$v)},expression:"item[key]"}})],1)}),0)]},proxy:true}],null,false,620373416)}):_vm._e()],1)],1)]),(_vm.item)?_c('ActionsPanel',{attrs:{"user":_vm.item,"visible":_vm.actionsVisible},on:{"hide":function($event){_vm.actionsVisible = false},"refresh":_vm.refresh,"change":_vm.change}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }