<template>
  <div>
    <div
      v-if="value"
      class="border flex cursor-pointer select-none items-center justify-center rounded-lg border-neutral-50 bg-white text-200 font-semibold"
    >
      <VueTailwindPicker
        :start-from-monday="true"
        start-date="2020-01-01"
        :selectedDate="value.toISOString().split('T')[0]"
        @change="updateValue"
      >
        <span class="block py-2 pl-4 pr-2">{{ value | date }}</span>
      </VueTailwindPicker>
      <div class="flex flex-col items-center justify-center">
        <IconChevron class="-mb-1 h-4" />
        <IconChevron class="-mt-1 h-4 rotate-180 transform" />
      </div>
    </div>
    <div class="classes-loader-for-compnent hidden">
      <div
        class="border border-b group inline-mode my-2px rounded smooth-scrolling text-2xl text-sm text-xs text-xxs transition w-1/7 w-88 xl:text-3xl static absolute relative inset-0 z-10 mx-1 ml-1 ml-2 mr-1 mr-2 mt-3 block flex h-4 h-8 h-auto w-8 w-auto max-w-xs flex-1 flex-shrink-0 cursor-not-allowed cursor-pointer select-none flex-row flex-wrap flex-nowrap items-center justify-center justify-between overflow-hidden overflow-y-auto overflow-x-hidden rounded-full rounded-l-full rounded-r-full border-dotted border-gray-200 border-transparent bg-gray-100 bg-red-500 bg-transparent p-1 p-2 px-2 py-1 py-2 pl-4 pr-2 text-center leading-none leading-relaxed text-gray-200 text-gray-700 text-green-400 text-red-400 text-white opacity-75 shadow-xs transition-all duration-150 ease-in-out ease-linear ease-out hover:border-red-500 hover:bg-gray-200"
      ></div>
    </div>
  </div>
</template>

<script>
import VueTailwindPicker from 'vue-tailwind-picker';

export default {
  name: 'DateSelector',
  components: {
    VueTailwindPicker
  },
  props: {
    value: {
      type: Date
    }
  },
  mounted() {},
  methods: {
    updateValue(value) {
      if (!(value instanceof Date)) {
        value = new Date(value);
      }
      this.$emit('input', value);
    }
  }
};
</script>
