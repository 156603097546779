<template>
  <div ref="container"></div>
</template>

<script>
import Lottie from 'lottie-web';

export default {
  name: 'Animation',
  props: {
    name: {
      type: String,
      required: true
    },
    loop: {
      type: Boolean,
      default: true,
      required: false
    },
    scenes: {
      type: Object,
      default() {
        return {};
      },
      required: false
    },
    currentScene: {
      type: String,
      default: '',
      required: false
    }
  },
  watch: {
    loop: {
      handler() {
        this.animation.loop = this.loop;
        this.playAnimation();
      }
    },
    currentScene: {
      handler() {
        this.playAnimation();
      }
    }
  },
  data() {
    return {
      animation: null,
      totalFrames: 0
    };
  },
  mounted() {
    if (!this.name || this.name.length === 0) {
      return;
    }

    // eslint-disable-next-line
    const data = require(`@/components/global/animations/${ this.name }.json`);

    this.animation = Lottie.loadAnimation({
      container: this.$refs.container,
      renderer: 'svg',
      loop: this.loop,
      autoplay: false,
      animationData: data
    });
    this.totalFrames = this.animation.getDuration(true);

    this.playAnimation();
  },

  methods: {
    playAnimation() {
      const sceneInfo = this.scenes[this.currentScene];
      if (this.currentScene.length === 0 || !sceneInfo) {
        this.animation.onEnterFrame = null;
        this.animation.play();
        return;
      }

      let animationProperties = {
        frames: [-1, -1],
        loop: false
      };

      if (Array.isArray(sceneInfo)) {
        animationProperties = {
          ...animationProperties,
          frames: sceneInfo
        };
      } else {
        animationProperties = {
          ...animationProperties,
          ...sceneInfo
        };
      }

      while (animationProperties.frames.length < 2) {
        animationProperties.frames.push(-1);
      }

      if (animationProperties.frames[0] === -1) {
        animationProperties.frames[0] = 0;
      }
      if (animationProperties.frames[1] === -1) {
        animationProperties.frames[1] = this.totalFrames;
      }

      this.animation.loop = animationProperties.loop;
      this.playSegments(
        animationProperties.frames[0],
        animationProperties.frames[1],
        animationProperties.next || '',
        animationProperties.nextDelay || 0
      );
    },

    playSegments(frameStart, frameStop, next = '', nextDelay = 0) {
      if (frameStart > frameStop) {
        this.animation.goToAndStop(frameStart, true);
        this.animation.setDirection(-1);
        this.animation.onEnterFrame = () => {
          if (Math.floor(this.animation.currentFrame) <= frameStop) {
            if (this.animation.loop) {
              this.playSegments(frameStart, frameStop);
            } else {
              this.animation.onEnterFrame = null;
              this.animation.goToAndStop(frameStop, true);

              if (next && next.length > 0) {
                setTimeout(() => {
                  this.$emit('next', next);
                }, nextDelay);
              }
            }
          }
        };

        this.animation.play();
      } else {
        this.animation.setDirection(1);
        this.animation.onEnterFrame = null;
        this.animation.playSegments([frameStart, frameStop], true);
        if (next && next.length > 0) {
          this.animation.onComplete = () => {
            this.animation.onComplete = null;
            setTimeout(() => {
              this.$emit('next', next);
            }, nextDelay);
          };
        }
      }
    }
  }
};
</script>
