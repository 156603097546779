<template>
  <div class="max-w-7xl m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-4 flex w-full flex-col items-start justify-start space-x-0 sm:flex-row sm:items-center sm:space-x-4">
      <div
        class="border transition flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
        @click="$router.go(-1)"
      >
        <IconChevronLeft />
      </div>
      <h1 class="text-700 font-bold">
        {{ $t('Menu.Management.Organization') }} {{ item ? item.name : $route.params.organizationID }}
      </h1>
      <EntityIcon entity="organization" />
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="secondary"
        size="md"
        :text="$t('Management.Organization.Action.Save')"
        @click="saveChanges"
      />
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t('Management.Organization.Action.OpenActions')"
        @click="actionsVisible = !actionsVisible"
      />
    </div>
    <div class="w-full">
      <div class="grid grid-cols-1 flex-col gap-8 pt-8 sm:grid-cols-2">
        <Card class="flex-col" :title="$t('Management.Organization.SectionGeneral')">
          <template v-slot:content>
            <div
              v-for="key in generalKeys"
              :key="`general-${key}`"
              class="flex w-full items-start gap-2 justify-between text-300 even:bg-gray-200"
              :class="{
                'rounded-md bg-tertiary px-2 font-bold': key === 'organization_plan_pay_by_invoice' && item[key] > 0
              }"
            >
              <span class="text-neutral-700">{{ key.replace(/_/g, ' ') | capitalize }}</span>
              <span v-if="key.indexOf('_date') > -1 || key === 'created' || key === 'updated' || key === 'deleted'">{{
                item[key] | date
              }}</span>
              <a v-else-if="key === 'email'" :href="`/search?search=${item[key]}`" class="underline">{{
                item[key] || '-'
              }}</a>
              <a
                v-else-if="key === 'mollie_customer_id'"
                :href="`https://my.mollie.com/dashboard/org_9453701/customers/${item[key]}`"
                target="_blank"
                class="underline"
                >{{ item[key] || '-' }}</a
              >
              <span
                v-else
                :class="{
                  'font-mono text-200': key.indexOf('_id') > 0 || key.indexOf('_uuid') > 0 || key === 'iban'
                }"
                >{{ item[key] }}</span
              >
            </div>

            <Tabs
              class="mt-4"
              :titles="[$t('Video.Stats.Usage'), $t('Menu.Management.User'), $t('Menu.Management.Invoice')]"
            >
              <template #tab1>
                <div v-if="item && item.minutes_usage">
                  <DataTable :data="item.minutes_usage" />
                </div>
              </template>

              <template #tab2>
                <div v-if="item && item.users">
                  <DataTable :data="item.users" @rowClicked="userRowClicked" />
                </div>
              </template>

              <template #tab3>
                <div v-if="item && item.invoices">
                  <DataTable :data="item.invoices" @rowClicked="invoiceRowClicked" />
                </div>
              </template>
            </Tabs>
          </template>
        </Card>

        <div class="flex flex-col gap-8">
          <Card v-if="organizationKeys" class="flex-col" :title="$t('Management.Organization.SectionOrganization')">
            <template v-slot:content>
              <form @submit.prevent="submitForm">
                <div class="mb-4 flex flex-col" v-for="key in organizationKeys" :key="`video-${key}`">
                  <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                  <InputElement v-model="item[key]" type="text" :placeholder="key" />
                </div>
              </form>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <ActionsPanel
      v-if="item"
      :organization="item"
      :visible="actionsVisible"
      @hide="actionsVisible = false"
      @refresh="refresh"
      @change="change"
    />
  </div>
</template>

<script>
import set from 'lodash/set';
import Card from '@/components/Card.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';

export default {
  name: 'Organization',
  components: {
    Card,
    ActionsPanel
  },
  data() {
    return {
      item: null,
      actionsVisible: false
    };
  },
  computed: {
    generalKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'consent',
        'payment_id',
        'payment_status',
        'registration_costs',
        'code_id',
        'is_prepaid',
        'image_path',
        'minutes_usage',
        'render_options',
        'users',
        'invoices'
      ];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    },
    organizationKeys() {
      if (!this.item) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'created',
        'updated',
        'deleted',
        'consent',
        'payment_id',
        'payment_status',
        'registration_costs',
        'code_id',
        'is_prepaid',
        'image_path',
        'reference',
        'plan_id',
        'organization_plan_id',
        'organization_plan_status',
        'organization_plan_start_date',
        'organization_plan_expiry_date',
        'organization_plan_pay_by_invoice',
        'minutes_usage',
        'render_options',
        'mollie_customer_id',
        'users',
        'invoices'
      ];
      const keys = Object.keys(this.item);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.item = await this.$api.get(`/admin/management/organization/${this.$route.params.organizationID}`);

      if (!this.item) {
        this.$router.push({ name: 'Dashboard' });
      }
    },

    async change(data) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        set(this.item, key, data[key]);
      }

      this.saveChanges();
    },

    async saveChanges() {
      if (typeof this.item.credit === 'string' && this.item.credit.includes(',')) {
        this.item.credit = this.item.credit.replace(',', '.');
      }

      await this.$api.post(`/admin/management/organization/${this.$route.params.organizationID}`, {
        organization: this.item
      });

      this.refresh();
      this.$api.message('Changes saved successfully');
    },

    userRowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      const routeData = { name: 'User', params: { userID: item.id } };

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    },

    invoiceRowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      event = event || window.event;

      const routeData = { name: 'Invoice', params: { invoiceID: item.id } };

      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
