<template>
  <div class="m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="flex flex-row items-center space-x-2 text-700 font-bold">
        <span>{{ $t('Menu.Management.ComfortUsage') }}</span>
      </h1>
      <DateSelector v-model="$app.startDate" />
      <DateSelector v-model="$app.endDate" />
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t('Management.Comfort.ExportCSV')"
        @click="exportCSV"
      />
    </div>
    <div v-if="response" class="max-w-7xl mt-8 flex w-full flex-col items-start justify-center">
      <div
        class="border m-auto mb-8 flex w-full max-w-3xl flex-row items-center justify-center rounded-md border-neutral-50 bg-white py-4"
      >
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.ComfortVideos') }}</span>
          <span>{{ response.comfort.length }}</span>
        </div>
        <div class="flex w-full flex-col items-center justify-center text-300">
          <span class="text-neutral-700">{{ $t('Video.ComfortAverageDuration') }}</span>
          <span>{{ averageCheckDuration | duration(1) }}</span>
        </div>
      </div>
      <span class="my-4 text-400 font-semibold"
        >{{ $t('Video.ComfortVideos') }} ({{ $app.startDate | date }} - {{ $app.endDate | date }})</span
      >
      <DataTable :data="response.comfort" @rowClicked="rowClicked" date-format="DD-MM-YYYY HH:mm" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComfortOverview',
  data() {
    return {
      response: null,
      averageCheckDuration: 0
    };
  },
  watch: {
    '$app.startDate': {
      handler() {
        this.refresh();
      }
    },
    '$app.endDate': {
      handler() {
        this.refresh();
      }
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.response = await this.$api.get(
        `/admin/management/comfort/overview?start=${this.$app.startDate.getTime() / 1000}&end=${
          this.$app.endDate.getTime() / 1000
        }`
      );

      if (this.response && this.response.comfort && this.response.comfort.length > 0) {
        this.averageCheckDuration = 0;
        for (let i = 0; i < this.response.comfort.length; i += 1) {
          const item = this.response.comfort[i];
          item.video_duration = item.duration_seconds;
          delete item.duration_seconds;

          if (!item.create_date || !item.finished_date) {
            continue;
          }

          const startDate = new Date(item.create_date);
          const finishedDate = new Date(item.finished_date);
          const checkDuration = (finishedDate.getTime() - startDate.getTime()) / 1000;

          item.check_duration = checkDuration;
          this.averageCheckDuration += checkDuration;
        }

        this.averageCheckDuration /= this.response.comfort.length;
      }
    },

    async exportCSV() {
      if (!this.response || !this.response.comfort || this.response.comfort.length === 0) {
        return;
      }

      let csv = 'data:text/csv;charset=utf-8,';
      let didAddKeys = false;
      for (let i = 0; i < this.response.comfort.length; i += 1) {
        const video = { ...this.response.comfort[i] };
        const keys = Object.keys(video) || [];

        if (!didAddKeys) {
          csv += `${keys.join(',')}\n`;
          didAddKeys = true;
        }

        const groomedValues = [];
        for (let j = 0; j < keys.length; j += 1) {
          const key = keys[j];
          let value = (video[key] || '').toString();

          if (key.includes('date')) {
            if (value !== 'In progress...') {
              value = value.replace('T', ' ').replace('.000Z', '');
            }
          }

          if (key.includes('amount') || key.includes('price') || key.includes('cost') || key.includes('income')) {
            value = this.$filters.money(value * 100).replace(',', '.');
          }

          value = value.replace(/[,]/gi, '');
          groomedValues.push(value);
        }

        csv += `${groomedValues.join(',')}\n`;
      }

      const filename = `t8-comfort-export-${this.$filters.date(this.$app.startDate).replace(/\s/, '-')}_${this.$filters
        .date(this.$app.endDate)
        .replace(/\s/, '-')}.csv`;
      const uri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', uri);
      link.setAttribute('download', filename);
      link.click();
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      const routeData = { name: 'Video', params: { videoID: item.id } };
      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
