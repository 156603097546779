import Vue from 'vue';
import Vuex from 'vuex';
import env from '@/env';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    shownModals: [],
    currentStage: '',
    uploads: [],
    user: null,
    jwt: null,
    email: '',
    forceRetranscribes: [],
    languages: {
      spoken: '',
      subtitles: ''
    }
  },
  mutations: {
    loadPersistedStore(state) {
      try {
        const data = localStorage.getItem('persisted_store');
        if (!data) {
          throw new Error('No store data');
        }

        let { shownModals, email, forceRetranscribes } = JSON.parse(data);

        if (!shownModals) {
          shownModals = state.shownModals;
        }

        if (!email) {
          email = '';
        }

        if (!forceRetranscribes) {
          forceRetranscribes = [];
        }

        this.replaceState({
          ...state,
          shownModals,
          email,
          forceRetranscribes
        });
      } catch {
        // Continue with an empty store.
      }
    },

    forceRetranscribe(state, videoId) {
      state.forceRetranscribes.push({
        identifier: videoId,
        date: new Date()
      });

      const hourAgo = new Date(Date.now() - 60 * 60 * 1000);
      state.forceRetranscribes = state.forceRetranscribes.filter((item) => {
        return item.date > hourAgo;
      });
    },

    currentStage(state, value) {
      if (value instanceof Number) {
        value = value < this.getters.stages.length ? this.getters.stages[value] : this.getters.stages[0];
      }

      state.currentStage = this.getters.stages.indexOf(value) > -1 ? value : this.getters.stages[0];
    },

    shownModal(state, value) {
      if (!state.shownModals) {
        state.shownModals = [];
      }

      state.shownModals.push(value);
    },

    email(state, value) {
      state.email = value || '';
    },

    user(state, value) {
      state.user = value || null;
    },

    jwt(state, value) {
      state.jwt = value || null;
    },

    languages(state, value) {
      state.languages = {
        ...state.languages,
        ...value
      };
    },

    removeUpload(state, value) {
      if (!value) {
        return;
      }

      const fileReference = value instanceof File ? value : value.file;
      for (let i = 0; i < state.uploads.length; i += 1) {
        const existingUpload = state.uploads[i];
        if (existingUpload.file.upload.uuid === fileReference.upload.uuid) {
          state.uploads.splice(i, 1);
          break;
        }
      }
    },

    upload(state, value) {
      const { id } = value;
      if (id === undefined || id === null) {
        return;
      }

      delete value.id;

      let found = false;
      for (let i = 0; i < state.uploads.length; i += 1) {
        const existingID = state.uploads[i].id;
        if (id === existingID) {
          found = true;
          state.uploads[i] = {
            ...state.uploads[i],
            ...value
          };
          break;
        }
      }

      if (!found) {
        state.uploads.push({
          id,
          ...value
        });
      }

      // Force update
      const { uploads } = state;
      state.uploads = [];
      state.uploads = uploads;
    },

    reset(state) {
      state.currentStage = '';
      state.uploads = [];
    }
  },
  modules: {},
  getters: {
    apiBaseURL() {
      return env.value('NUXT_PUBLIC_API_URL', 'http://localhost:8100');
    },

    editorBaseURL() {
      return env.value('NUXT_PUBLIC_EDITOR_URL', 'https://editor.triple8.tv');
    },

    premiumPlan(state) {
      if (!state.metadata || !state.metadata.plans || state.metadata.plans.length === 0) {
        return null;
      }

      return state.metadata.plans.find((plan) => {
        return plan.id === 4;
      });
    },

    stages() {
      return ['Upload', 'Analyze', 'Language', 'Order'];
    },

    languages(_, getters) {
      return (
        getters.languagesInfo.map((language) => {
          return language.code;
        }) || []
      );
    },

    languagesInfo() {
      return [
        { code: 'nl-NL', flag: 'nl', input: true, output: true },
        { code: 'fy-NL', flag: 'nl', input: false, output: true },
        // { code: 'nl-BE', flag: 'be', input: true, output: true },
        { code: 'en-US', flag: 'gb', input: true, output: true },
        { code: 'de-DE', flag: 'de', input: true, output: true },
        { code: 'es-ES', flag: 'es', input: true, output: true },
        { code: 'pt-PT', flag: 'pt', input: true, output: true },
        { code: 'fr-FR', flag: 'fr', input: true, output: true },
        { code: 'it-IT', flag: 'it', input: true, output: true },
        { code: 'ja-JP', flag: 'jp', input: true, output: true },
        { code: 'zh-ZH', flag: 'cn', input: true, output: true },
        { code: 'ko-KO', flag: 'kr', input: true, output: true },
        { code: 'ar-AR', flag: 'sa', input: true, output: true }
      ];
    },

    application() {
      return {
        commit: process.env.VERSION_COMMIT,
        build: process.env.VERSION_BUILD,
        date: process.env.VERSION_DATE
      };
    }
  }
});

// Initialize persistance
store.commit('loadPersistedStore');
store.subscribe((mutation, state) => {
  if (localStorage) {
    localStorage.setItem(
      'persisted_store',
      JSON.stringify({
        shownModals: state.shownModals,
        email: state.email,
        forceRetranscribes: state.forceRetranscribes
      })
    );
  }
});

export default store;
