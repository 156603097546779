<template>
  <div class="m-auto flex w-full flex-col items-start pb-32">
    <div class="mb-8 flex w-full items-center justify-start space-x-4">
      <h1 class="flex flex-row items-center space-x-2 text-700 font-bold">
        <span>{{ $t('Menu.Management.Comfort') }}</span>
      </h1>
    </div>
    <div class="mb-4 flex w-full flex-row items-center justify-start space-x-4">
      <ButtonElement
        class="flex-shrink-0"
        variant="outline"
        size="md"
        :text="$t('Management.Comfort.ShowComfortUsage')"
        @click="showComfortUsage"
      />
    </div>
    <div v-if="response" class="max-w-7xl mt-8 flex w-full flex-col items-start justify-center">
      <div
        v-if="response.info"
        class="border m-auto mb-8 flex w-full max-w-3xl flex-row items-center justify-center rounded-md border-neutral-50 bg-white py-4"
      >
        <div
          v-for="(value, key) in response.info"
          :key="key"
          class="flex w-full flex-col items-center justify-center text-300"
        >
          <span class="text-neutral-700">{{ key.replace(/_/g, ' ') | capitalize }}</span>
          <span v-if="key.includes('wallet')">{{ (response.info[key] * 100) | money }}</span>
          <span v-else>{{ response.info[key] }}</span>
        </div>
      </div>
      <span class="my-4 text-400 font-semibold">{{ $t('Video.ComfortVideosInProgress') }}</span>
      <DataTable :data="response.transcriptions" @rowClicked="rowClicked" date-format="DD-MM-YYYY HH:mm" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComfortOverview',
  data() {
    return {
      response: null
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const response = await this.$api.get('/admin/management/comfort/inprogress');

      response.transcriptions = response.transcriptions.map((transcription) => {
        transcription.video_duration = transcription.duration_seconds;
        delete transcription.duration_seconds;

        const created = new Date(transcription.create_date);
        const now = new Date();
        transcription.check_duration = (now.getTime() - created.getTime()) / 1000;

        return transcription;
      });

      this.response = response;
    },

    async showComfortUsage() {
      this.$router.push({ name: 'ComfortUsage' });
    },

    rowClicked(item, event) {
      if (!item || !item.id) {
        return;
      }

      const routeData = { name: 'Video', params: { videoID: item.id } };
      if (event.ctrlKey || event.metaKey) {
        const resolvedRoute = this.$router.resolve(routeData);
        window.open(resolvedRoute.href, '_blank');
      } else {
        this.$router.push(routeData);
      }
    }
  }
};
</script>
