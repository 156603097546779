import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';

export default function _(value, precisionLevel = 0) {
  if (value === null || value === undefined) {
    return 0;
  }

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const durationInfo = dayjs.duration(Math.floor(value), 'seconds').locale('nl');
  let days = durationInfo.days();
  if (durationInfo.months() > 0) {
    days += durationInfo.months() * 30;
  }
  if (durationInfo.years() > 0) {
    days += durationInfo.years() * 365;
  }

  if (precisionLevel > 0) {
    if (precisionLevel === 1) {
      let time = `${durationInfo.format('H[h] mm')} min`;
      if (days > 0) {
        time = `${days}d ${time}`;
      }
      return time;
    }

    if (precisionLevel === 2) {
      let time = durationInfo.format('HH:mm:ss');
      if (days > 0) {
        time = `${days}d ${time}`;
      }
      return time;
    }

    return durationInfo.format('HH:mm:ss.SSS');
  }

  return durationInfo.humanize();
}
