<template>
  <div>
    <div v-if="!item">Not found.</div>
    <div v-if="item" class="m-auto flex w-full flex-col items-start pb-32">
      <div class="mb-4 flex w-full items-center justify-start gap-x-2 space-x-0 sm:items-center sm:space-x-4">
        <div
          class="border transition flex h-10 w-10 flex-shrink-0 cursor-pointer select-none items-center justify-center rounded-full border-neutral-50 bg-white duration-150 ease-in-out hover:border-neutral-200 hover:shadow-sm"
          @click="backButton"
        >
          <IconChevronLeft />
        </div>
        <h1 class="flex items-start gap-x-1 space-x-0 text-400 font-bold sm:items-center sm:space-x-2 sm:text-700">
          <span>{{ $t('Menu.Management.Video') }}</span>
          <span v-if="item" class="sm:truncate block max-w-sm break-all sm:max-w-3xl">{{
            item.video.original_filename
          }}</span>
          <EntityIcon entity="video" />
        </h1>
      </div>

      <div class="mb-4 flex w-full flex-row items-center justify-end space-x-4">
        <ButtonElement
          class="flex-shrink-0"
          variant="secondary"
          size="md"
          :text="$t('Management.Video.Action.Save')"
          @click="saveChanges"
        />
        <ButtonElement
          class="flex-shrink-0"
          variant="outline"
          size="md"
          :text="$t('Management.Video.Action.OpenActions')"
          @click="actionsVisible = !actionsVisible"
        />
      </div>
      <div class="w-full">
        <div class="grid grid-cols-1 flex-col gap-8 pt-8 sm:grid-cols-2">
          <Card class="flex-col" :title="$t('Management.Video.SectionGeneral')">
            <template v-slot:content>
              <div
                v-for="(value, key) in item.general"
                :key="`general-${key}`"
                class="flex w-full items-center justify-between text-300 even:bg-gray-200"
                :class="{
                  'rounded-md bg-tertiary px-2 font-bold':
                    key === 'delete_date' && item.general[key] && item.general[key].length > 0
                }"
              >
                <span class="text-neutral-700">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                <span v-if="key.includes('_date') || key === 'created' || key === 'updated' || key === 'deleted'">{{
                  value | date('DD MMMM YYYY HH:mm')
                }}</span>
                <a
                  :href="podUrl(value)"
                  target="_blank"
                  v-else-if="key === 'render_pod'"
                  class="text-right font-mono text-200 underline"
                  >{{ value }}</a
                >
                <span
                  v-else-if="key === 'user_id'"
                  class="cursor-pointer text-blue-500 underline"
                  @click="$router.push({ name: 'User', params: { userID: value } })"
                  >{{ value }}</span
                >
                <span
                  v-else-if="key === 'organization_id'"
                  class="cursor-pointer text-blue-500 underline"
                  @click="$router.push({ name: 'Organization', params: { organizationID: value } })"
                  >{{ value }}</span
                >
                <span v-else-if="key === 'duration_seconds'" class="font-mono text-200"
                  >{{ Math.round(value * 100) / 100 }} ({{ Math.ceil(value / 60) }} min)</span
                >
                <a
                  v-else-if="key === 'payment_id' && !value.startsWith('RSLR')"
                  :href="`https://my.mollie.com/dashboard/org_9453701/payments/${value}`"
                  target="_blank"
                  class="underline"
                  >{{ value || '-' }}</a
                >
                <span
                  v-else
                  :class="{
                    'break-all text-right font-mono text-200': key.includes('_id') || key.includes('_uuid')
                  }"
                  >{{ value }}</span
                >
              </div>

              <Tabs
                :titles="[
                  `Transcriptions (${(item && item.transcriptions && item.transcriptions.length) || 0})`,
                  'Actions',
                  'Metadata'
                ]"
                class="mt-8"
              >
                <template #tab1>
                  <span
                    v-if="retranscribeDate"
                    class="animate-pulse rounded-sm bg-secondary text-center text-300 font-bold text-white"
                    >Retranscribing... ({{ retranscribeDate | timeago }})</span
                  >
                  <div class="grid w-full grid-cols-5 items-center justify-between text-200 text-neutral-700">
                    <span>ID</span>
                    <span>Language</span>
                    <span>Engine</span>
                    <span>Confidence</span>
                    <span>Status</span>
                  </div>
                  <div
                    v-for="(value, index) in item.transcriptions || []"
                    :key="`transcriptions-${index}`"
                    class="grid w-full grid-cols-5 items-center justify-between text-200 even:bg-gray-200"
                    :class="{
                      'animate-pulse font-bold': value.in_progress,
                      'font-bold': value.engine === 'editor'
                    }"
                  >
                    <span class="text-neutral-700">{{ value.id }}</span>
                    <span>{{ value.language_code }}</span>
                    <span>{{ value.engine }}</span>
                    <span>{{ value.confidence }}</span>
                    <span>{{ value.in_progress ? 'In progress' : 'Done' }}</span>
                  </div>
                </template>
                <template #tab2>
                  <div class="py-4 px-2 text-200 flex flex-col gap-4">
                    <a
                      :href="`https://cloud.digitalocean.com/spaces/triple8?i=f3170c&path=videos%2F${item.video.uuid}%2F`"
                      target="_blank"
                      class="underline"
                      >Open Digital Ocean Spaces</a
                    >
                  </div>
                </template>
                <template #tab3>
                  <div class="py-4 px-2 text-200 flex flex-col gap-4">
                    <span class="whitespace-pre-wrap font-mono">{{ videoMetadata }}</span>
                  </div>
                </template>
              </Tabs>
            </template>
          </Card>

          <div class="flex flex-col gap-8">
            <Card v-if="videoKeys" class="flex-col" :title="$t('Management.Video.SectionVideo')">
              <template v-slot:content>
                <form @submit.prevent="submitForm">
                  <div class="mb-4 flex flex-col" v-for="key in videoKeys" :key="`video-${key}`">
                    <div class="flex items-center gap-x-2">
                      <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                    </div>
                    <div class="relative w-full">
                      <InputElement
                        v-model="item.video[key]"
                        type="text"
                        :placeholder="key"
                        :class="{
                          'pr-8': (item.video[key] || '').toString().startsWith('http')
                        }"
                      />
                      <div
                        v-if="(item.video[key] || '').toString().startsWith('http')"
                        class="absolute right-0 top-0 flex h-full cursor-pointer items-center px-2 text-100 font-bold"
                      >
                        <span class="bg-white px-1" @click="openLink(item.video[key])">Open</span>
                        <span
                          v-if="key === 'thumbnail_video_filename'"
                          class="border-l bg-white px-1"
                          @click="setThumbnailToOriginal(item.video[key])"
                          >Set Original</span
                        >
                      </div>
                      <div
                        v-else-if="key === 'mimetype'"
                        class="absolute right-0 top-0 flex h-full cursor-pointer items-center gap-x-2 px-2 text-100 font-bold"
                      >
                        <span class="rounded-md bg-neutral-100 px-1" @click="setMimeType('video/mp4')">MP4</span>
                        <span class="rounded-md bg-neutral-100 px-1" @click="setMimeType('video/quicktime')">MOV</span>
                      </div>
                    </div>
                  </div>
                </form>

                <div v-if="item.video.thumbnail_audio_filename" class="border rounded-md border-neutral-50">
                  <img :src="item.video.thumbnail_audio_filename" alt="" class="h-12 w-full object-cover" />
                  <audio controls preload="metadata" class="w-full">
                    <source :src="item.video.audio_filename.replace('.flac', '.mp3')" type="audio/mpeg" />
                  </audio>
                </div>
              </template>
            </Card>

            <Card v-if="purchaseKeys" class="flex-col" :title="$t('Management.Video.SectionPurchase')">
              <template v-slot:content>
                <form @submit.prevent="submitForm">
                  <div class="mb-4 flex flex-col" v-for="key in purchaseKeys" :key="`purchase-${key}`">
                    <span class="text-300">{{ key.replace(/_/g, ' ') | capitalize }}</span>
                    <InputElement v-model="item.purchase[key]" type="text" :placeholder="key" />
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>

      <ActionsPanel
        v-if="item"
        :video="item.video"
        :purchase="item.purchase"
        :visible="actionsVisible"
        @hide="actionsVisible = false"
        @refresh="refresh"
        @change="change"
      />
    </div>
  </div>
</template>

<script>
import set from 'lodash/set';
import Card from '@/components/Card.vue';
import ActionsPanel from '@/components/ActionsPanel.vue';

export default {
  name: 'Video',
  components: {
    Card,
    ActionsPanel
  },
  data() {
    return {
      item: null,
      actionsVisible: false
    };
  },
  computed: {
    videoMetadata() {
      if (!this.item?.video?.metadata?.length) {
        return '-';
      }

      try {
        return JSON.stringify(JSON.parse(this.item.video.metadata), null, 2);
      } catch {
        return '-';
      }
    },

    retranscribeDate() {
      if (!this.item.video || this.item.video.uuid.length === 0) {
        return null;
      }

      for (let i = 0; i < (this.$store.state.forceRetranscribes || []).length; i += 1) {
        const info = this.$store.state.forceRetranscribes[i];
        if (info.identifier === this.item.video.id) {
          return info.date;
        }
      }

      return null;
    },

    videoKeys() {
      if (!this.item || !this.item.video) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'uuid',
        'rendered_filename',
        'rendered_square_filename',
        'thumbnail_filename',
        'thumbnail_audio_filename',
        'render_pod',
        'duration_seconds',
        'create_date',
        'external_upload_date',
        'external_upload_retries',
        'external_jobid',
        'rendered_date',
        'render_progress',
        'update_date',
        'delete_date',
        'folder_id',
        'user_id',
        'organization_id',
        'render_options',
        's3_upload_token',
        'local_upload_token',
        'target_languages',
        'language',
        'metadata'
      ];
      const keys = Object.keys(this.item.video);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    },

    purchaseKeys() {
      if (!this.item || !this.item.purchase) {
        return null;
      }

      const forbiddenKeys = [
        'id',
        'video_id',
        'plan_id',
        'payment_id',
        'language',
        'paid',
        'human_assistance',
        'requested_upgrade_info',
        'payment_info',
        'create_date',
        'payment_date',
        'user_id',
        'organization_id'
      ];
      const keys = Object.keys(this.item.purchase);
      return keys.filter((key) => {
        if (forbiddenKeys.includes(key)) {
          return false;
        }
        return true;
      });
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    backButton() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'VideoOverview' });
      }
    },

    async refresh() {
      this.item = await this.$api.get(`/admin/management/video/${this.$route.params.videoID}`);
    },

    openLink(link) {
      window.open(link);
    },

    setMimeType(mimeType) {
      this.item.video.mimetype = mimeType;
    },

    setThumbnailToOriginal() {
      this.item.video.thumbnail_video_filename = this.item.video.filename;
    },

    podUrl(value) {
      const isDevelop = (value || '').includes('develop');
      return `https://rancher.gravity.nl/dashboard/c/c-dk49n/explorer/pod/nmsp-platform-${
        isDevelop ? 'develop' : 'master'
      }/${value}#containers`;
    },

    async change(data) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        set(this.item, key, data[key]);
      }

      this.saveChanges();
    },

    async saveChanges() {
      await this.$api.post(`/admin/management/video/${this.$route.params.videoID}`, {
        video: this.item.video,
        purchase: this.item.purchase
      });

      this.refresh();
      this.$api.message('Changes saved successfully');
    }
  }
};
</script>
